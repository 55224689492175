import {
    Anchor,
    ButtonProps,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";

import {
    Field,
    Form,
    Formik
} from 'formik';
import {
    FlexiButton,
    FlexiCancelButton,
    FormikCheckbox,
    FormikPasswordInput,
    FormikTextInput
} from "@flexinet/ui-components";

import {useSignUpForm} from "../hooks/useSignUpForm.tsx";
import {SignUpFormRecordSchema} from "./RecordSchema.tsx";
import {useNavigate} from "react-router-dom";
import {SocialSignUpFieldset} from "./SocialSignUpFieldset.tsx";


const RegisterButton = (props: ButtonProps & { type: string }) => <FlexiButton {...props} />

export const SignUpForm = () => {

    const navigate = useNavigate();

    const {
        initialValues,
        handleSubmit
    } = useSignUpForm()


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={SignUpFormRecordSchema}
                // validate={validate}
                onSubmit={handleSubmit}
            >
                {({
                      isSubmitting,
                  }) => (

                    <Form>
                        <SocialSignUpFieldset/>

                        <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Sign Up</Text></Title>} px={'xl'}
                                  mb={'xl'}>

                            <Field
                                name={'given_name'}
                                label={'Given Name'}
                                description={<Text size={'0.8em'} fw={500}>i.e. John</Text>}
                                my={'lg'}
                                component={FormikTextInput}
                            />

                            <Field
                                name={'family_name'}
                                label={'Family Name'}
                                description={<Text size={'0.8em'} fw={500}>i.e. Doe</Text>}
                                my={'lg'}
                                component={FormikTextInput}
                            />

                            <Field
                                name={'email'}
                                label={'Email'}
                                type="email"
                                description={<Text size={'0.8em'} fw={500}>i.e. john.doe@mycompany.com</Text>}
                                my={'lg'}
                                component={FormikTextInput}
                            />
                            <Field
                                name="password"
                                type="password"
                                label="Password"
                                description={<Text size={'0.8em'} fw={500}>Min 8 characters. At least 1 number, 1
                                    special
                                    character,
                                    1 uppercase letter, 1 lowercase letter</Text>
                                }
                                my={'lg'}
                                component={FormikPasswordInput}
                            />

                            <Field
                                name="confirmPassword"
                                type="password"
                                label="Confirm Password"
                                description={<Text size={'0.8em'} fw={500}>Please retype your selected password</Text>
                                }
                                my={'lg'}
                                component={FormikPasswordInput}
                            />

                            <Field
                                name="acknowledgment"
                                label={<Text size={'0.9em'}>
                                    By creating an account, you acknowledge and agree with the <Anchor
                                    href="https://www.flexi.network/terms-of-service" target={'_blank'}>Terms of
                                    Service</Anchor>
                                </Text>}
                                my={'xl'}
                                component={FormikCheckbox}
                            />


                            <Group justify={'flex-end'}>
                                <FlexiCancelButton variant={'subtle'} onClick={() => navigate('/sign-in')}/>
                                <RegisterButton variant={'filled'} disabled={isSubmitting}
                                                type={'submit'}>Register</RegisterButton>
                            </Group>
                        </Fieldset>

                    </Form>

                )}
            </Formik>
        </div>
    )
};

