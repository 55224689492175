import {
    Center,
    Group,
    Text
} from "@mantine/core";

import {Link} from "react-router-dom";

export const LinkToSignInPage = () => {
    return (
        <Center>
            <Group>
                <Text>Have an account?</Text>
                <Link to={'/sign-in'}>Sign In</Link>
            </Group>
        </Center>
    );

}
