import {Container} from "@mantine/core";
import {
    FlexiCancelButton,
    IndexPageLayout,
    PlatformLoader
} from "@flexinet/ui-components";
import {useNavigate} from "react-router-dom";
import {RecordForm} from "../forms/RecordForm.tsx";
import {useEffect} from "react";
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {
    UserAttribute,
    UserAttributes
} from "../types.ts";
import {useUserAttribute} from "../hooks/useUserAttribute.ts";


export const EditPage = () => {

    const navigate = useNavigate();

    const {
        items,
        fetchRecords,
    } = useUserAttributes()

    const {
        updateRecord
    } = useUserAttribute()

    useEffect(() => {
        fetchRecords()
    }, []);


    const handleSubmit = async (values: UserAttributes) => {

        Object.keys(values)
              .forEach(key => {
                  updateRecord(key, {
                      id: key,
                      name: key,
                      value: values[key]
                  });
              })


    }


    if (!items || items.length === 0) {
        return <PlatformLoader message={'Loading attributes...Please, wait!'}/>
    }

    const userAttributes = items.reduce((acc: UserAttributes, curr: UserAttribute) => {
        acc[curr.name as string] = curr.value;
        return acc;
    }, {})

    return (
        <IndexPageLayout title={'Update Profile'}
                         tools={[<FlexiCancelButton onClick={() => navigate('/account')}>Cancel</FlexiCancelButton>]}>
            <Container>
                <RecordForm record={userAttributes}
                            handleSubmit={handleSubmit}
                />
            </Container>
        </IndexPageLayout>
    );
};
