import {
    ButtonProps,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";

import {
    Field,
    Form,
    Formik
} from 'formik';
import {
    FlexiButton,
    FormikTextInput
} from "@flexinet/ui-components";
import {ConfirmSignUpFormRecordSchema} from "./RecordSchema.tsx";
import {useConfirmSignUpForm} from "../hooks/useConfirmSignUpForm.tsx";


const ResetButton = (props: ButtonProps & { type: string }) => <FlexiButton {...props}>Reset</FlexiButton>
const RegisterButton = (props: ButtonProps & { type: string }) => <FlexiButton {...props} />

export const ConfirmSignUpForm = () => {

    const {
        initialValues,
        handleSubmit
    } = useConfirmSignUpForm()


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={ConfirmSignUpFormRecordSchema}
                onSubmit={handleSubmit}
            >
                {({
                      isSubmitting,
                  }) => (

                    <Form>
                        <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Confirm Sign Up</Text></Title>}
                                  px={'xl'}
                                  mb={'xl'}>

                            <Field
                                name={'username'}
                                label={'Email'}
                                type="email"
                                description={<Text size={'0.8em'} fw={500}>i.e. john.doe@mycompany.com</Text>}
                                my={'lg'}
                                component={FormikTextInput}
                            />
                            <Field
                                name={'confirmationCode'}
                                label={'Code'}
                                description={<Text size={'0.8em'} fw={500}>received code</Text>}
                                my={'lg'}
                                component={FormikTextInput}
                            />


                            <Group justify={'flex-end'}>
                                <ResetButton type={'reset'} variant={'subtle'}/>
                                <RegisterButton variant={'filled'} disabled={isSubmitting}
                                                type={'submit'}>Confirm</RegisterButton>
                            </Group>
                        </Fieldset>

                    </Form>

                )}
            </Formik>
        </div>
    )
};

