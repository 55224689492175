import {
    Center,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";
import {FlexiButton} from "@flexinet/ui-components";
import {AiOutlineGoogle,} from "react-icons/ai";
import {
    signInWithRedirect,
    SignInWithRedirectInput
} from "aws-amplify/auth";


export const SocialSignUpFieldset = () => {

    const googleSignInWithRedirectInput = {provider: 'Google'} as SignInWithRedirectInput

    return (
        <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Sign Up with Social Account</Text></Title>}
                  px={'xl'}
                  mb={'xl'}>
            <Center>
                <Group
                    my={'lg'}
                >
                    <FlexiButton variant={'outline'} size={'xl'}
                                 leftSection={<AiOutlineGoogle/>}
                                 onClick={(e) => {
                                     e.preventDefault()
                                     signInWithRedirect(googleSignInWithRedirectInput)
                                 }}>Google</FlexiButton>


                </Group>
            </Center>
        </Fieldset>

    )
}

