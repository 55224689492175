import {
    Box,
    Container,
    Group
} from "@mantine/core";
import {ReactNode} from "react";

import classes from './SignUpPageGrid.module.css';

export const SignUpPageGrid = ({
                                   leftPanel,
                                   rightPanel
                               }: { leftPanel: ReactNode, rightPanel?: ReactNode }) => {


    return (
        <Group className={classes.root}>
            <Box className={classes.forms}>
                <Container px={'15%'}>
                    {leftPanel}
                </Container>
            </Box>

            <Box className={classes.marketing}>
                {rightPanel ? rightPanel : null}
            </Box>
        </Group>
    );

}
