import {
    ButtonProps,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";

import {
    Field,
    Form,
    Formik
} from 'formik';
import {
    FlexiButton,
    FlexiCancelButton,
    FormikPasswordInput,
    FormikTextInput
} from "@flexinet/ui-components";
import {ConfirmResetPasswordRecordSchema} from "./RecordSchema.tsx";
import {useConfirmResetPasswordForm} from "../hooks/useConfirmResetPasswordForm.tsx";
import {useNavigate} from "react-router-dom";


const SubmitButton = (props: ButtonProps & { type: string }) => <FlexiButton {...props} />

export const ConfirmResetPasswordForm = () => {
    const navigate = useNavigate()

    const {
        initialValues,
        handleSubmit,
    } = useConfirmResetPasswordForm()


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={ConfirmResetPasswordRecordSchema}
                // validate={validate}
                onSubmit={handleSubmit}
            >
                {({
                      isSubmitting,
                  }) => (
                    <>

                        <Form>
                            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Reset Password</Text></Title>}
                                      px={'xl'}
                                      mb={'xl'}>
                                <Field
                                    name={'username'}
                                    label={'Email'}
                                    type="email"
                                    description={<Text size={'0.8em'} fw={500}>i.e. john.doe@mycompany.com</Text>}
                                    my={'lg'}
                                    component={FormikTextInput}
                                />

                                <Field
                                    name={'confirmationCode'}
                                    label={'Code'}
                                    description={<Text size={'0.8em'} fw={500}>Enter confirmation code</Text>}
                                    my={'lg'}
                                    component={FormikTextInput}
                                />

                                <Field
                                    name="newPassword"
                                    label="New Password"
                                    type="password"
                                    description={<Text size={'0.8em'} fw={500}>Min 8 characters. At least 1 number, 1
                                        special
                                        character,
                                        1 uppercase letter, 1 lowercase letter</Text>
                                    }
                                    my={'lg'}
                                    component={FormikPasswordInput}
                                />


                                <Group justify={'flex-end'}>
                                    <FlexiCancelButton variant={'subtle'} onClick={() => navigate('/sign-in')}/>
                                    <SubmitButton disabled={isSubmitting} type={'submit'}>Submit</SubmitButton>
                                </Group>
                            </Fieldset>

                        </Form>

                    </>

                )}
            </Formik>
        </div>
    )
};

