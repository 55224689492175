import {
    Center,
    Group,
    Text
} from "@mantine/core";

import {Link} from "react-router-dom";

export const LinkToConfirmSignUpPage = () => {
    return (
        <Center>
            <Group>
                <Text>Need to confirm an account?</Text>
                <Link to={'/sign-up/confirm'}>Confirm Sign Up</Link>
            </Group>
        </Center>
    );

}
