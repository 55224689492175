import {RecordForm} from '../forms/RecordForm';


import {useUserApplication} from '../hooks/useUserApplication';
import {IndexPageNavigation} from "../components/IndexPageNavigation";
import {FormPageLayout} from "@flexinet/ui-components";
import {useAuth} from "../../../hooks/useAuth.ts";


export const AddPage = () => {

    const {user} = useAuth()

    if (!user) {
        throw new Error('user is required parameter')
    }

    const {
        record,
        createRecord,
    } = useUserApplication(user.username);

    return (
        <FormPageLayout
            title='Add User Application'
            pageNavigation={<IndexPageNavigation/>}
        >
            <RecordForm record={record} handleSubmit={createRecord}/>
        </FormPageLayout>
    );
};
