import {Cache} from 'aws-amplify/utils';
import {useSearchParams} from "react-router-dom";
import {useAuth} from "./useAuth.ts";

export const useReturnTo = () => {

    const [params] = useSearchParams();

    const {
        user,
        idToken,
        accessToken
    } = useAuth();


    const saveReturnTo = () => {
        if (params.get('returnTo')) {
            Cache.setItem('returnTo', params.get('returnTo'))
        }
    };

    const getReturnTo = async () => {
        return await Cache.getItem('returnTo')
    };

    const generateRedirectUrl = async () => {

        const returnTo = await getReturnTo()

        if (returnTo) {

            if (typeof user === 'undefined') {
                setTimeout(() => {
                    return;
                }, 1000)
                //throw new Error('user is undefined')
            }

            if (typeof accessToken === 'undefined') {
                throw new Error('accessToken is undefined')
            }

            if (typeof idToken === 'undefined') {
                throw new Error('idToken is undefined')
            }

            console.debug('returnTo in the hook', returnTo)
            Cache.removeItem('returnTo');
            const params = new URLSearchParams();
            params.append('expires_in', '3600');
            params.append('token_type', 'Bearer');
            params.append('id_token', idToken.toString());
            params.append('access_token', accessToken.toString());
            return returnTo + '#' + params.toString();
        }

        return ''
    };

    const generateRedirectUrlWithUser = async () => {

        const returnTo = Cache.getItem('returnTo');

        if (returnTo) {

            if (typeof accessToken === 'undefined') {
                throw new Error('accessToken is undefined')
            }

            if (typeof idToken === 'undefined') {
                throw new Error('idToken is undefined')
            }


            console.debug('returnTo in the hook', returnTo)
            Cache.removeItem('returnTo');
            const params = new URLSearchParams();
            params.append('expires_in', '3600');
            params.append('token_type', 'Bearer');
            params.append('id_token', idToken.toString());
            params.append('access_token', accessToken.toString());
            return returnTo + '#' + params.toString();
        }

        return ''
    };

    return {
        saveReturnTo,
        getReturnTo,
        generateRedirectUrl,
        generateRedirectUrlWithUser
    };
};
