import {I18n} from '@aws-amplify/core';

import {
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {
    Box,
    Button,
} from '@mantine/core';

import {useNavigate} from 'react-router-dom';

import {useUserApplications} from '../hooks/useUserApplications';
import PropTypes from 'prop-types';

import {FaRegObjectGroup} from 'react-icons/fa'
import {
    AiOutlineAppstore,
    AiOutlinePlus
} from 'react-icons/ai'
import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {useAuth} from "../../../hooks/useAuth.ts";

const ComponentsReloadButton = () => {

    const {user} = useAuth()

    if (!user) {
        throw new Error('user is required parameter')
    }

    const {fetchRecords} = useUserApplications(user.username);
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add User Application',
                             size = 'default',
                             onClick,
                         }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <Button
            variant={"filled"}
            disabled={false}
            leftSection={<AiOutlinePlus/>}
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </Button>
    );
};


const ManageCollectionsButton = ({
                                     buttonText = 'Manage App Collections',
                                     onClick,
                                 }: {
    buttonText?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('/application-collections');
        };
    }

    return (
        <Button
            radius={'sm'}
            size={'sm'}
            variant={'outline'}
            disabled={false}
            leftSection={<FaRegObjectGroup/>}
            onClick={onClick}
        >
            {I18n.get(buttonText)}
        </Button>
    );
};

const AppStoreButton = ({
                            buttonText = 'Discover Applications',
                            onClick,
                        }: {
    buttonText?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('/tenant-applications');
        };
    }

    return (
        <Button
            radius={'sm'}
            size={'sm'}
            variant={'outline'}
            disabled={false}
            leftSection={<AiOutlineAppstore/>}
            onClick={onClick}
        >
            {I18n.get(buttonText)}
        </Button>
    );
};

const useTools = () => {

    const {evaluateAction} = useAccessControl()

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (evaluateAction('ListTenantApplications')) {
        tools.push(<AppStoreButton key='app-store'/>);
    }

    if (evaluateAction('CreateApplicationCollection')) {
        tools.push(<ManageCollectionsButton key='manage'/>);
    }

    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('My Applications')}
            tools={useTools()}
        />
    );
};

AddRecordButton.propTypes = {
    buttonText: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
};