import {
    Center,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";

import {
    Field,
    Form,
    Formik
} from 'formik';
import {
    FlexiCancelButton,
    FlexiSignInButton,
    FormikPasswordInput,
    FormikTextInput
} from "@flexinet/ui-components";
import {useSignInForm} from "../hooks/useSignInForm.tsx";
import {SignInRecordSchema} from "./RecordSchema.tsx";
import {SocialSignInFieldset} from "./SocialSignInFieldset.tsx";
import {UserAccountProfile} from "../components/UserAccountProfile.tsx";
import {useNavigate} from "react-router-dom";


export const SignInForm = () => {


    const {
        initialValues,
        handleSubmit,
    } = useSignInForm()

    const navigate = useNavigate();

    const handleClear = async () => {
        navigate('/sign-in', {replace: true})
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={SignInRecordSchema}
                // validate={validate}
                onSubmit={handleSubmit}
            >
                {({
                      values,
                      isSubmitting,
                  }) => {


                    return (
                        <>
                            <Form>

                                <SocialSignInFieldset username={values.username}/>

                                <Center
                                    mb={'xl'}
                                >or</Center>


                                <Fieldset
                                    legend={<Title order={3}><Text tt={'uppercase'}>Username and
                                        Password</Text></Title>}
                                    px={'xl'}
                                    mb={'xl'}>

                                    {values.username ? <UserAccountProfile username={values.username as string}
                                                                           onClear={handleClear}/> :
                                        <Field
                                            name={'username'}
                                            label={'Email'}
                                            type="email"
                                            description={<Text size={'0.8em'} fw={500}>i.e.
                                                john.doe@mycompany.com</Text>}
                                            my={'lg'}
                                            component={FormikTextInput}
                                        />}


                                    <Field
                                        name="password"
                                        label="Password"
                                        type="password"
                                        description={<Text size={'0.8em'} fw={500}>Min 8 characters. At least 1 number,
                                            1
                                            special
                                            character,
                                            1 uppercase letter, 1 lowercase letter</Text>
                                        }
                                        my={'lg'}
                                        component={FormikPasswordInput}
                                    />
                                    <Group justify={'flex-end'}>
                                        <FlexiCancelButton variant={'subtle'} onClick={() => navigate('/sign-in')}/>
                                        <FlexiSignInButton variant={'filled'} disabled={isSubmitting} type={'submit'}/>
                                    </Group>
                                </Fieldset>


                            </Form>

                        </>

                    )
                }
                }
            </Formik>
        </div>
    )
};

