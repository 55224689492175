import PropTypes from 'prop-types';
import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@flexinet/ui-components';

import {useNavigate} from "react-router-dom";
import {stage} from "../../../config/stage";
import {User} from "../../account/types.ts";
import {UserAttributes} from "../types.ts";

export const RecordForm = ({
                               record,
                               handleSubmit,
                           }: {
    record: Omit<UserAttributes, 'id'>,
    handleSubmit: (values: UserAttributes) => void
}) => {
    const navigate = useNavigate()

    const onSubmit = (values: UserAttributes) => {
        handleSubmit(values);
        navigate('/account');
    };

    const onCancel = () => {
        navigate('/account');
    };


    let initialValues = {} as User;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent {...rest} />
                </FormWithButtons>)}
        </Formik>
    );
};

RecordForm.propTypes = {
    record: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
};
