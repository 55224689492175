import {
    Center,
    Paper,
    Text
} from '@mantine/core';
import PropTypes from "prop-types";
import {IconContext} from "react-icons";
import {DashletType} from "../types.ts";


export const Dashlet = ({item}: {
    item: DashletType
}) => {


    return (
        <Paper
            style={{cursor: "pointer"}}
            withBorder
            radius="md"
            p="xs"
            shadow={'md'}
            key={item.collectionName}
            onClick={() => window.location.assign(item.location)}
        >

            <Center my={'md'}>
                <IconContext.Provider value={{size: '72'}}>
                    {item.icon}
                </IconContext.Provider>
            </Center>

            <Center>
                <Text
                    my={'md'}
                    // color="dimmed"
                    size="sm"
                    tt="uppercase"
                    fw={800}
                >
                    {item.app}
                </Text>
            </Center>


        </Paper>

    );

};


Dashlet.propTypes = {
    item: PropTypes.object
}