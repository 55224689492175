import PropTypes from 'prop-types';

import {FormikTextInput} from '@flexinet/ui-components';
import {Field} from "formik";


export const FormComponent = () => {

    return (
        <Field
            name='name'
            label='Name'
            description='Please enter the application name'
            withAsterisk
            component={FormikTextInput}
        />
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
