// import {useAppDispatch} from "../../../hooks/useTypedReactRedux";


import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {visibilitySelector} from "../selectors";
import {fetchUserAttributes} from "../reducers/recordsSlice.ts";
import {UserAttribute} from "../types.ts";

export const useUserAttributes = () => {

    const dispatch = useAppDispatch();

    const {
        items
    } = useAppSelector((state) => visibilitySelector(state));

    const sub = items.find((item: UserAttribute) => item.name === 'sub')?.value
    const zoneInfo = items.find((item: UserAttribute) => item.name === 'zoneInfo')?.value
    const givenName = items.find((item: UserAttribute) => item.name === 'givenName')?.value
    const familyName = items.find((item: UserAttribute) => item.name === 'familyName')?.value
    const email = items.find((item: UserAttribute) => item.name === 'email')?.value
    const website = items.find((item: UserAttribute) => item.name === 'website')?.value
    const address = items.find((item: UserAttribute) => item.name === 'address')?.value
    const phoneNumber = items.find((item: UserAttribute) => item.name === 'phoneNumber')?.value
    const profile = items.find((item: UserAttribute) => item.name === 'profile')?.value
    const picture = items.find((item: UserAttribute) => item.name === 'picture')?.value
    const locale = items.find((item: UserAttribute) => item.name === 'locale')?.value
    const nickname = items.find((item: UserAttribute) => item.name === 'nickname')?.value
    const accountId = items.find((item: UserAttribute) => item.name === 'accountId')?.value
    const accountAdmin = items.find((item: UserAttribute) => item.name === 'accountAdmin')?.value
    const gender = items.find((item: UserAttribute) => item.name === 'gender')?.value
    const birthDate = items.find((item: UserAttribute) => item.name === 'birthDate')?.value

    const fetchRecords = async () => {
        dispatch(fetchUserAttributes())
    };

    return {
        items,
        sub,
        zoneInfo,
        givenName,
        familyName,
        email,
        website,
        address,
        phoneNumber,
        profile,
        picture,
        locale,
        nickname,
        accountId,
        accountAdmin,
        gender,
        birthDate,
        fetchRecords,
    }
}
