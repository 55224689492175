import {useAuth} from "../../../hooks/useAuth.ts";
import {
    useNavigate,
    useSearchParams
} from "react-router-dom";
import {
    FlexiSignInButton,
    PlatformLoader
} from "@flexinet/ui-components";
import {
    Center,
    Stack,
    Title
} from "@mantine/core";

import {Cache} from 'aws-amplify/utils';
import platform from "../../../config/platform.ts";

export const AuthorizePage = () => {

    const navigate = useNavigate()

    const [params] = useSearchParams();

    const redirectUrl = params.get('redirect_uri')
    const responseType = params.get('response_type')
    const clientId = params.get('client_id')
    const identityProvider = params.get('identity_provider')
    const scope = params.get('scope')
    const state = params.get('state')

    Cache.setItem('authorization', {
        redirectUrl,
        responseType,
        clientId,
        identityProvider,
        scope,
        state
    })


    const {user} = useAuth()


    const {
        idToken,
        accessToken,
    } = useAuth();


    // const {
    //     customState,
    //     saveCustomState
    // } = useCustomState()

    // useEffect(() => {
    //     saveCustomState()
    // }, [])


    if (user) {

        if (typeof idToken === 'undefined' || typeof accessToken === 'undefined') {
            return <PlatformLoader/>
        }


        const platformAppKeys = Object.keys(platform as { [key: string]: string }) as string[]
        const allowedRedirectUrls = platformAppKeys.map(name => platform[name])


        if (!allowedRedirectUrls.includes(redirectUrl as string)) {
            throw new Error(`Invalid redirect url ${redirectUrl}`)
        }

        const returnUrl = `${redirectUrl}#id_token=${idToken}&access_token=${accessToken}&token_type=Bearer&expires_in=3600&state=${state}`
        console.debug("RETURN URL", returnUrl)
        Cache.removeItem('authorization')
             .then(() => {
                 window.location.assign(returnUrl)
             })

    } else {

        return (<Center>
            <Stack>
                <Title my={'xl'}>Session has expired. Please, sign in again!</Title>
                <Center my={'xl'}>
                    <FlexiSignInButton onClick={() => navigate('/')}/>
                </Center>
            </Stack>
        </Center>)

    }


}
