import {
    useEffect,
    useState
} from "react";
import {EmailAddressForm} from "../forms/EmailAddressForm.tsx";
import {useWindowScroll} from "@mantine/hooks";
import {LinkToSignUpPage} from "../../sign-up/components/LinkToSignUpPage.tsx";
import {LinkToResetPasswordPage} from "../../reset-password/components/LinkToResetPasswordPage.tsx";
import {SignInPageGrid} from "../components/SignInPageGrid.tsx";
import {useAuth} from "../../../hooks/useAuth.ts";
import {Navigate} from "react-router-dom";
import {Cache} from "aws-amplify/utils";
import {PlatformLoader} from "@flexinet/ui-components";

export const IndexPage = () => {

    const {
        user,
        idToken,
        accessToken,
    } = useAuth()


    const [destination, setDestination] = useState('')
    useEffect(() => {
        const redirectAuthenticated = async () => {

            const authorization = await Cache.getItem('authorization')
            console.debug({authorization})
            if (typeof user !== 'undefined' && typeof idToken !== 'undefined' && typeof accessToken !== 'undefined') {
                if (authorization && typeof authorization.redirectUrl !== 'undefined') {
                    const returnUrl = `${authorization.redirectUrl}#id_token=${idToken}&access_token=${accessToken}&token_type=Bearer&expires_in=3600&state=${authorization.state}`
                    console.debug("RETURN URL", returnUrl)
                    setDestination(returnUrl)
                    await Cache.removeItem('authorization')
                } else {
                    setDestination('dashboard')
                }
            }
        }

        redirectAuthenticated()

    }, [user, idToken, accessToken])

    const [, scrollTo] = useWindowScroll();

    useEffect(() => {
        scrollTo({y: 0})
    }, []);

    if (typeof user !== 'undefined' && typeof idToken !== 'undefined' && typeof accessToken !== 'undefined') {
        // navigate('/dashboard')

        console.debug("DESTINATION", destination)

        if (destination === '') {
            return <PlatformLoader/>
        } else {
            if (destination === 'dashboard') {
                return <Navigate to={'/dashboard'}/>
            } else {
                window.location.assign(destination)
            }
        }

    }

    return (
        <SignInPageGrid>
            <EmailAddressForm/>
            <LinkToSignUpPage/>
            <LinkToResetPasswordPage/>
        </SignInPageGrid>
    );

}
