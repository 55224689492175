import {useEffect} from "react";
import {useWindowScroll} from "@mantine/hooks";
import {ConfirmSignInForm} from "../forms/ConfirmSignInForm.tsx";
import {LinkToSignUpPage} from "../../sign-up/components/LinkToSignUpPage.tsx";
import {LinkToResetPasswordPage} from "../../reset-password/components/LinkToResetPasswordPage.tsx";
import {SignInPageGrid} from "../components/SignInPageGrid.tsx";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useNavigate} from "react-router-dom";

export const ConfirmPage = () => {
    const navigate = useNavigate()

    const [, scrollTo] = useWindowScroll();

    useEffect(() => {
        scrollTo({y: 0})
    }, []);

    const {user} = useAuth()
    if (user) {
        navigate('/dashboard')
    }

    return (
        <SignInPageGrid>
            <ConfirmSignInForm/>
            <LinkToResetPasswordPage/>
            <LinkToSignUpPage/>
        </SignInPageGrid>
    );

}
