import * as Yup from 'yup';

export const IdentifierRecordSchema = Yup.object()
                                         .shape({
                                                    'username': Yup.string()
                                                                   .email()
                                                                   .required('email address is required field'),
                                                });


export const SignInRecordSchema = Yup.object()
                                     .shape({
                                                'username': Yup.string()
                                                               .email()
                                                               .required('email address is required field'),
                                                'password': Yup.string()
                                                               .min(8, 'Minimum 8 chars')
                                                               .required('password is required field'),
                                            });


export const ConfirmSignInFormRecordSchema = Yup.object()
                                                .shape({
                                                           'username': Yup.string()
                                                                          .email()
                                                                          .required('email address is required field'),
                                                           'confirmationCode': Yup.string()
                                                                                  .required('password is required field'),
                                                       });
