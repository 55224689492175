import {
    Center,
    Container,
    Stack,
    Title
} from "@mantine/core";

export const RightHandPanel = ({context}: {
    context: { greeting: string, enabled: boolean, application: string, plan: string }
}) => {

    if (context.application && context.plan) {
        return (
            <>
                <Container mt={'xl'}>
                    <Center>
                        <img src={'1024x1024_logo.png'} width={100}/>
                    </Center>
                    <Stack my={'xl'}>
                        <Title>Selected Application: </Title>
                        {context.application}
                    </Stack>
                    <Stack my={'xl'}>
                        <Title>Selected Plan: </Title>
                        {context.plan}
                    </Stack>
                </Container>
            </>
        )
    }

    return (
        <Container>
            {/*<Image src={'/1024x1024_logo.png'} radius={'md'} width={400} height={400}/>*/}
            {context.enabled && context.greeting}
        </Container>
    )

}


