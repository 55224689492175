import {
    ActionIcon,
    ButtonProps,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";

import {
    Field,
    Form,
    Formik
} from 'formik';

import {
    FlexiButton,
    FormikCheckbox,
    FormikTextInput,
} from "@flexinet/ui-components";
import {
    AiOutlineArrowRight,
    AiOutlineClose
} from "react-icons/ai";
import {useEmailAddressForm} from "../hooks/useEmailAddressForm.tsx";
import {IdentifierRecordSchema} from "./RecordSchema.tsx";
import {useEffect} from "react";
import {Cache} from "aws-amplify/utils";
import {useNavigate} from "react-router-dom";

const ContinueButton = (props: ButtonProps & { type: string }) => <FlexiButton {...props} />

export const EmailAddressForm = () => {

    const {
        initialValues,
        handleSubmit
    } = useEmailAddressForm()


    const navigate = useNavigate();

    const modified = initialValues
    useEffect(() => {
        Cache.getItem('sign-in-preferences')
             .then(preferences => {
                 if (preferences && preferences.username) {
                     modified.username = preferences.username
                     modified.rememberMe = preferences.rememberMe
                 }
             })
    })


    const handleClear = async () => {
        await Cache.removeItem('sign-in-preferences')
        navigate('/', {replace: true})
    }


    return (
        <Formik
            initialValues={modified}
            validationSchema={IdentifierRecordSchema}
            // validate={validate}
            onSubmit={handleSubmit}
        >
            {({
                  isSubmitting
              }) => (

                <Form>
                    <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Sign In</Text></Title>} px={'xl'}
                              mb={'xl'}>


                        <Field
                            name='username'
                            label={'Email'}
                            type="text"
                            description={<Text size={'0.8em'} fw={500}>i.e. john.doe@mycompany.com</Text>}
                            my={'lg'}
                            component={FormikTextInput}
                            rightSection={<ActionIcon variant={'subtle'}
                                                      type={'reset'}
                                                      onClick={handleClear}><AiOutlineClose/></ActionIcon>}
                        />


                        <Field
                            name={'rememberMe'}
                            label={'Remember me'}
                            my={'lg'}
                            component={FormikCheckbox}
                        />


                        <Group justify={'flex-end'}>
                            <ContinueButton disabled={isSubmitting} type={'submit'}
                                            size={'md'}
                                            leftSection={<AiOutlineArrowRight/>}>Continue</ContinueButton>
                        </Group>

                    </Fieldset>

                </Form>

            )}
        </Formik>
    )


};

