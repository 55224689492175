import {Navigate} from "react-router-dom";
import {useAuth} from "../../../hooks/useAuth.ts";

export const PublicHomePage = () => {


    const {
        user,
    } = useAuth();


    if (typeof user !== 'undefined' && user.username) {
        return <Navigate to={'/dashboard'}/>
    } else {
        return <Navigate to={'/sign-in'}/>;
    }

};
