import {
    Center,
    Container,
    Grid,
    Text,
    Title
} from "@mantine/core";
import {
    FormikCheckbox,
    FormWithButtons,
    IndexPageLayout
} from "@flexinet/ui-components";
import {
    Field,
    Formik
} from "formik";
import {useNavigate} from "react-router-dom";


export const FormComponent = () => {

    return (
        <Container>
            <Grid>
                <Grid.Col span={12}>
                    <Title order={2}>Essential</Title>
                </Grid.Col>
                <Grid.Col span={10}>
                    <Text>Essential cookies are necessary to provide our site and services and
                        cannot be
                        deactivated.
                        They are usually set in response to your actions on the site, such as
                        setting
                        your
                        privacy
                        preferences, signing in, or filling in forms.</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                    <Center>
                        <Field name="essential" disabled={true} component={FormikCheckbox}></Field>
                    </Center>
                </Grid.Col>


                <Grid.Col span={12}>
                    <Title order={2}>Performance</Title>
                </Grid.Col>
                <Grid.Col span={10}>
                    <Text>Performance cookies provide anonymous statistics about how customers
                        navigate
                        our site
                        so
                        we
                        can improve site experience and performance. Approved third parties may
                        perform
                        analytics on
                        our
                        behalf, but they cannot use the data for their own purposes.</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                    <Center>
                        <Field name="performance" component={FormikCheckbox}></Field>
                    </Center>
                </Grid.Col>

                <Grid.Col span={12}>
                    <Title order={2}>Functional</Title>
                </Grid.Col>
                <Grid.Col span={10}>
                    <Text>Functional cookies help us provide useful site features, remember your
                        preferences, and display relevant content. Approved third parties may
                        set
                        these cookies to provide certain site features. If you do not allow
                        these
                        cookies, then some
                        or all of these services
                        may not function properly.</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                    <Center>
                        <Field name="functional" component={FormikCheckbox}/>
                    </Center>
                </Grid.Col>

            </Grid>
        </Container>);
}


export const CookiePreferencesPage = () => {

    const navigate = useNavigate();

    const initialValues = {
        essential: true,
        performance: true,
        functional: true,
    }

    const handleSubmit = (values: any) => {
        console.log(values);
    }

    const handleCancel = (values: any) => {
        console.log(values);
        navigate('/account')
    }

    return (
        <IndexPageLayout title={'Customize Cookie Preferences'} tools={[]}>
            <Center>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {(props) => {
                        return (
                            <FormWithButtons handleSubmit={props.handleSubmit} handleCancel={handleCancel}
                                             isSubmitting={props.isSubmitting}>
                                <FormComponent/>
                            </FormWithButtons>
                        );
                    }}
                </Formik>
            </Center>
        </IndexPageLayout>
    );
};
