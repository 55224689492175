// import {useAppDispatch} from "../../../hooks/useTypedReactRedux";


import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {UserAttribute} from "../types.ts";
import {recordSelector} from "../selectors.ts";
import {
    deleteUserAttribute,
    fetchUserAttribute,
    updateUserAttribute
} from "../reducers/recordsSlice.ts";

export const useUserAttribute = (id?: string) => {

    const dispatch = useAppDispatch();

    const {
        record,
        loading
    } = useAppSelector((state) => recordSelector(state, id ? id : ''));


    const fetchRecord = async () => {
        if (typeof id === 'undefined') {
            throw new Error('No id provided')
        }
        dispatch(fetchUserAttribute(id))
    };

    const updateRecord = async (id: string, record: UserAttribute) => {
        if (typeof id === 'undefined') {
            throw new Error('No id provided')
        }
        dispatch(updateUserAttribute({
                                         id,
                                         record
                                     }))
    }

    const deleteRecord = async (id: string) => {
        if (typeof id === 'undefined') {
            throw new Error('No id provided')
        }
        dispatch(deleteUserAttribute(id))
    }

    return {
        record,
        loading,
        fetchRecord,
        updateRecord,
        deleteRecord
    }
}
