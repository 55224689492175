import {
    PlatformNavbar,
    PlatformNavbarMenuItem
} from "@flexinet/ui-components";
import {findParent} from "../data/menus.tsx";
import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {useLocation} from "react-router-dom";


export const PortalNavbar = () => {

    // @see this - https://github.com/aws-amplify/amplify-ui/blob/main/packages/ui/src/types/authenticator/user.ts
    // @see this - https://ui.docs.amplify.aws/react/guides/auth-protected
    // @see this - https://github.com/aws-amplify/amplify-ui/blob/main/guides/react/protected-routes/src/components/Layout.jsj
    // https://ui.docs.amplify.aws/react/connected-components/authenticator


    const {evaluateAction} = useAccessControl()

    const {pathname} = useLocation()
    const itemsParent = findParent(pathname)
    const items = itemsParent?.links ?? []

    const menuItems = items.filter((item: PlatformNavbarMenuItem) => {
        return evaluateAction(item.rbac as string)
    });


    return <PlatformNavbar
        navbarHeader='Flexi Network Portal'
        menuItems={menuItems}/>

};