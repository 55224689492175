import {stage} from './stage';


const platform = {
    prod: {
        portal: 'https://portal.flexi.network',
        console: 'https://console.flexi.network',
        domains: 'https://www.flexi.domains',
        email: 'https://www.flexi.email',
        bot: 'https://www.flexi.bot',
        stripe: 'https://stripe.flexi.network',
        marketplace: 'https://marketplace.flexi.network',
        billing: 'https://billing.flexi.network',
    },
    test: {
        portal: 'https://portal.test.flexi.network',
        console: 'https://console.test.flexi.network',
        domains: 'https://test.flexi.domains',
        email: 'https://test.flexi.email',
        bot: 'https://test.flexi.bot',
        stripe: 'https://stripe.test.flexi.network',
        marketplace: 'https://marketplace.test.flexi.network',
        billing: 'https://billing.test.flexi.network',
    },
    dev: {
        portal: 'https://portal.dev.flexi.network',
        console: 'https://console.dev.flexi.network',
        domains: 'https://dev.flexi.domains',
        email: 'https://dev.flexi.email',
        bot: 'https://dev.flexi.bot',
        stripe: 'https://stripe.dev.flexi.network',
        marketplace: 'https://marketplace.dev.flexi.network',
        billing: 'https://billing.dev.flexi.network',
    },
    ide: {
        portal: 'https://localhost:8011',
        console: 'https://localhost:8012',
        domains: 'https://localhost:8021',
        email: 'https://localhost:8023',
        bot: 'https://localhost:8022',
        stripe: 'https://localhost:8013',
        marketplace: 'https://localhost:8014',
        billing: 'https://localhost:8015',
    },
} as { [key: string]: { [key: string]: string } };

export default platform[stage];
