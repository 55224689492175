import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {IconContext} from "react-icons";
import {v4 as uuidv4} from 'uuid';
import {
    AiOutlineAppstore,
    AiOutlineClockCircle,
    AiOutlineDelete,
    AiOutlineHome,
    AiOutlineKey,
    AiOutlineUser
} from "react-icons/ai";
import {PiCookieLight} from "react-icons/pi";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Home',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: 'GetDashboard',
            links: [
                {
                    id: uuidv4(),
                    link: '/dashboard',
                    label: 'Dashboard',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
                    rbac: 'GetDashboard',
                }, {
                    id: uuidv4(),
                    link: '/user-applications',
                    label: 'My Applications',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineAppstore/></IconContext.Provider>,
                    rbac: 'ListUserApplications',
                }] as PlatformNavbarMenuItem[],
        },
        {
            id: uuidv4(),
            link: '/account',
            label: 'Account',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineUser/></IconContext.Provider>,
            rbac: 'GetUserAccount',
            links: [
                {
                    id: uuidv4(),
                    link: '/account',
                    label: 'Account',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineUser/></IconContext.Provider>,
                    rbac: 'GetUserAccount',
                },
                {
                    id: uuidv4(),
                    link: '/reset-password/change',
                    label: 'Reset Password',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineKey/></IconContext.Provider>,
                    rbac: 'GetUserAccount',
                },
                {
                    id: uuidv4(),
                    link: '/account/cookie-preferences',
                    label: 'Cookies',
                    icon: <IconContext.Provider value={{size: '2em'}}><PiCookieLight/></IconContext.Provider>,
                    rbac: 'GetUserAccount',
                },
                {
                    id: uuidv4(),
                    link: '/account/delete-account',
                    label: 'Delete Account',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineDelete/></IconContext.Provider>,
                    rbac: 'GetUserAccount',
                }] as PlatformNavbarMenuItem[],
        },
        {
            id: uuidv4(),
            link: '/subscriptions',
            label: 'Billing',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineClockCircle/></IconContext.Provider>,
            rbac: 'ListSubscriptions',
            links: [
                {
                    id: uuidv4(),
                    link: '/subscriptions',
                    label: 'Subscriptions',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineClockCircle/></IconContext.Provider>,
                    rbac: 'ListSubscriptions',
                }, {
                    id: uuidv4(),
                    link: '/subscriptions',
                    label: 'Payment Method',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineClockCircle/></IconContext.Provider>,
                    rbac: 'ListPaymentMethods',
                }, {
                    id: uuidv4(),
                    link: '/subscriptions',
                    label: 'Addresses',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineClockCircle/></IconContext.Provider>,
                    rbac: 'ListPaymentAddresses',
                }, {
                    id: uuidv4(),
                    link: '/subscriptions',
                    label: 'Invoices',
                    icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineClockCircle/></IconContext.Provider>,
                    rbac: 'ListInvoices',
                }] as PlatformNavbarMenuItem[]
        }] as PlatformNavbarMenuItem[];

};

export const findParent = (link: string) => {
    const menuItems = getMenuItems();
    let found = {} as PlatformNavbarMenuItem
    menuItems.forEach(item => {
        if (item.links) {
            item.links.forEach(child => {
                if (child.link === link) {
                    found = item;
                }
            })
        }
    })
    return found as PlatformNavbarMenuItem;
}

