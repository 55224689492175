import {useCallback} from "react";
import {
    resetPassword,
    ResetPasswordInput
} from "aws-amplify/auth";
import {useNavigate} from "react-router-dom";
import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo
} from "react-icons/ai";

interface ForgotPasswordFormFields {
    username: string;
}


export const useForgotPasswordForm = () => {

    const navigate = useNavigate();

    const initialValues: ForgotPasswordFormFields = {
        username: '',
    };


    const submitForm = async (values: ForgotPasswordFormFields) => {

        const input = {
            username: values.username
        } as ResetPasswordInput

        try {

            const output = await resetPassword(input)

            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({
                                          'event': 'reset_password',
                                      });
            }

            const {nextStep} = output;

            const codeDeliveryDetails = nextStep.codeDeliveryDetails;

            switch (nextStep.resetPasswordStep) {
                case 'CONFIRM_RESET_PASSWORD_WITH_CODE':

                    showNotification({
                                         title: 'Notification',
                                         icon: <AiOutlineInfo/>,
                                         message: `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,
                                         color: 'green',
                                     })

                    // Collect the confirmation code from the user and pass to confirmResetPassword.
                    navigate('/reset-password/confirm', {state: {username: values.username}})

                    break;

                case 'DONE':

                    showNotification({
                                         title: 'Notification',
                                         icon: <AiOutlineInfo/>,
                                         message: `Successfully reset password. Please, sign in`,
                                         color: 'green',
                                     })

                    navigate('/sign-in')
                    break;

            }


        } catch (e: any) {
            console.error(e)

            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: e.message,
                                 color: 'red',
                             })

        }


    }

    const handleSubmit = useCallback(
        (values: ForgotPasswordFormFields) => {
            return submitForm(values)
                .then(() => {
                    console.log('Form submitted!');
                });
        },
        [],
    );

    return {
        initialValues,
        handleSubmit,
    };
};
