import PropTypes from 'prop-types';


import {
    Box,
    Paper,
    Stack
} from '@mantine/core';


import {UserApplication} from "../types.ts";
import {UserApplicationLogo} from "./UserApplicationLogo.tsx";

const UserApplicationGridProfile = ({cloudApplication}: { cloudApplication: UserApplication }) => {

    // const {recordAppExit} = useAnalytics();

    const handleClick = async () => {

        // recordAppExit(cloudApplication);


        const appLink = cloudApplication.url as string;
        const linkFragment = cloudApplication.linkFragment as string;

        if (cloudApplication['integration-options'] && cloudApplication['integration-options']['http-method'] === 'POST') {

            const form = document.createElement('form')
            form.setAttribute('method', 'POST');
            form.setAttribute('action', appLink);
            form.setAttribute('target', '_blank')

            const input = document.createElement('input');
            input.setAttribute('type', 'text')
            input.setAttribute('name', 'idToken')
            input.setAttribute('value', linkFragment)
            form.appendChild(input)

            document.getElementById('root')
                    ?.appendChild(form)

            form.submit()

        } else {
            const linkUrl = [appLink, linkFragment].join('/');
            window.open(linkUrl);
        }


    };


    return (
        <Paper onClick={handleClick} className="pac-app-card">
            <Stack align="center">
                <Box mt="1em" style={{height: '10em'}}>
                    <UserApplicationLogo record={cloudApplication}/>
                </Box>

                <Box
                    style={{
                        textAlign: 'center',
                        inlineSize: '90%',
                        overflowWrap: 'break-word',
                    }}
                >
                    {cloudApplication.name}
                </Box>

            </Stack>
        </Paper>
    );
};

export default UserApplicationGridProfile;

UserApplicationGridProfile.propTypes = {
    cloudApplication: PropTypes.shape({
                                          name: PropTypes.string,
                                          'app-link': PropTypes.string,
                                          'link-fragment': PropTypes.string,
                                          'expires-on': PropTypes.string,
                                      }),
};
