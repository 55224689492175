import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          givenName: Yup.string()
                                                        .required('Required'),
                                          familyName: Yup.string()
                                                         .required('Required'),
                                          email: Yup.string()
                                                    .email('Invalid email address')
                                                    .required('Required')
                                      });


export const DeleteAccountFormSchema = Yup.object()
                                          .shape({
                                                     confirmation: Yup.string()
                                                                      .required('Required'),
                                                 });
