import {
    Outlet,
    useNavigate
} from 'react-router-dom';


import {
    PlatformAppShell,
    PlatformHeader,
    PlatformHeaderNavigationMenu,
    PlatformHeaderToolbar,
    PlatformLoader,
    PlatformNavbarMenuItem,
    PlatformPublicAppShell
} from '@flexinet/ui-components';
import {
    Suspense,
    useEffect
} from "react";
import {PortalNavbar} from "./menu-items/pages/PortalNavbar.tsx";
import {useUserAttributes} from "../features/user-attributes/hooks/useUserAttributes.ts";
import {AiOutlineLogout} from "react-icons/ai";
import config from "../config";
import {getMenuItems} from "./menu-items/data/menus.tsx";

export const AppLayout = () => {
    const navigate = useNavigate()

    const {
        givenName,
        familyName,
        email,
        fetchRecords: fetchUserAttributes
    } = useUserAttributes()


    useEffect(() => {
        fetchUserAttributes()
    }, [])

    let userInfo = undefined
    if (givenName && familyName && email) {
        userInfo = {
            name: [givenName, familyName].join(' '),
            email: email
        }
    }

    const additionalMenuItems = [{
        title: 'Sign Out',
        link: '/sign-out',
        onClick: () => navigate('/sign-out'),
        icon: <AiOutlineLogout/>
    }]

    const navbar = email ? <PortalNavbar/> : undefined

    const menuItems = [] as { title: string, onClick: () => void }[]

    if (!email) {

        return <PlatformPublicAppShell
            platformHeader={<PlatformHeader
                navigationMenu={<PlatformHeaderNavigationMenu menuItems={menuItems}/>}/>}
        >
            <Suspense fallback={<PlatformLoader/>}>
                <Outlet/>
            </Suspense>
        </PlatformPublicAppShell>

    }

    let topLevelMenuItems = [] as PlatformNavbarMenuItem[]
    let navigationMenuItems = [] as { title: string, onClick: () => void }[]
    if (email) {
        topLevelMenuItems = getMenuItems()
        navigationMenuItems = topLevelMenuItems.map(item => ({
            title: item.label,
            onClick: () => navigate(item.link)
        }))
    }

    return (
        <PlatformAppShell
            platformNavbar={navbar}
            platformHeader={
                <PlatformHeader
                    navigationMenu={<PlatformHeaderNavigationMenu menuItems={navigationMenuItems}/>}
                    toolbar={<PlatformHeaderToolbar
                        user={userInfo ? userInfo : undefined}
                        portalUrl={config.platform.portal}
                        additionalMenuItems={additionalMenuItems}/>}/>
            }
        >


            <Suspense fallback={<PlatformLoader/>}>
                <Outlet/>
            </Suspense>


        </PlatformAppShell>
    );


};
