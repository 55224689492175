import {useCallback} from "react";

import {
    confirmResetPassword,
    ConfirmResetPasswordInput
} from 'aws-amplify/auth';
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo
} from "react-icons/ai";


interface ConfirmResetPasswordFormFields {
    username: string;
    confirmationCode: string;
    newPassword: string;
}


export const useConfirmResetPasswordForm = () => {

    const navigate = useNavigate()

    const {state} = useLocation();

    const initialValues: ConfirmResetPasswordFormFields = {
        username: state && state.username ? state.username : '',
        confirmationCode: '',
        newPassword: ''
    };


    const submitForm = async (values: ConfirmResetPasswordFormFields) => {

        const input = {...values} as ConfirmResetPasswordInput;

        try {

            await confirmResetPassword(input)

            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({
                                          'event': 'confirm_reset_password',
                                      });
            }


            showNotification({
                                 title: 'Notification',
                                 icon: <AiOutlineInfo/>,
                                 message: `Password reset successful.`,
                                 color: 'teal',
                             })


            navigate('/')

        } catch (e: any) {

            console.error(e)

            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: e.message,
                                 color: 'red',
                             })

        }

    }

    const handleSubmit = useCallback(
        (values: ConfirmResetPasswordFormFields) => {
            return submitForm(values)
                .then(() => {
                    console.log('Form submitted!');
                });
        },
        [],
    );

    return {
        initialValues,
        handleSubmit,
    };
};
