import * as Yup from 'yup';


export const ForgotPasswordRecordSchema = Yup.object()
                                             .shape({
                                                        'username': Yup.string()
                                                                       .email()
                                                                       .required('email address is required field'),
                                                    });


export const ConfirmResetPasswordRecordSchema = Yup.object()
                                                   .shape({
                                                              'username': Yup.string()
                                                                             .email()
                                                                             .required('email address is required field'),
                                                              'confirmationCode': Yup.string()
                                                                                     .required('code is required field'),
                                                              'newPassword': Yup.string()
                                                                                .min(8, 'Minimum 8 chars')
                                                                                .required('password is required field'),
                                                          });
