import {ResetPasswordForm} from "../forms/ResetPasswordForm.tsx";
import {useEffect} from "react";
import {useWindowScroll} from "@mantine/hooks";
import {LinkToSignUpPage} from "../../sign-up/components/LinkToSignUpPage.tsx";
import {ResetPasswordPageGrid} from "../components/ResetPasswordPageGrid.tsx";

export const IndexPage = () => {
    const [, scrollTo] = useWindowScroll();

    useEffect(() => {
        scrollTo({y: 0})
    }, []);


    return (
        <ResetPasswordPageGrid>
            <ResetPasswordForm/>
            <LinkToSignUpPage/>
        </ResetPasswordPageGrid>

    );

}
