import {ConfirmSignUpForm} from "../forms/ConfirmSignUpForm.tsx";
import {LinkToSignInPage} from "../../sign-in/components/LinkToSignInPage.tsx";
import {SignUpPageGrid} from "../components/SignUpPageGrid.tsx";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../hooks/useAuth.ts";


export const ConfirmPage = () => {

    const navigate = useNavigate()

    const {user} = useAuth()
    if (user) {
        navigate('/dashboard')
    }

    return (
        <SignUpPageGrid leftPanel={
            <><ConfirmSignUpForm/>
                <LinkToSignInPage/>
            </>}/>
    );

}
