import {
    createContext,
    FunctionComponent,
    ReactNode,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import {
    AuthUser,
    fetchAuthSession,
    getCurrentUser,
    JWT
} from 'aws-amplify/auth';


type AuthContextType = {
    user: AuthUser | undefined,
    idToken?: JWT | undefined,
    accessToken?: JWT | undefined,
    cognitoGroups?: string[] | undefined,
    role?: string | undefined,
}


export const AuthContext = createContext<AuthContextType>({
                                                              user: {} as AuthUser,
                                                          });

interface IAuthProvider {
    children?: ReactNode
}

export const AuthProvider: FunctionComponent<IAuthProvider> = ({children}) => {

    const [user, setUser] = useState<AuthUser | undefined>();
    const [idToken, setIdToken] = useState<JWT | undefined>();
    const [accessToken, setAccessToken] = useState<JWT | undefined>();
    const [cognitoGroups, setCognitoGroups] = useState<string[] | undefined>();
    const [role, setRole] = useState<string | undefined>();

    useEffect(() => {
        const fetchCognitoUser = async () => {

            try {
                const cognitoUser = await getCurrentUser()
                setUser(cognitoUser)
            } catch (error) {
                console.trace(
                    'AUTH CONTEXT. FETCH USER ERROR',
                    error
                );
            }
        };

        fetchCognitoUser();
    }, []);


    useEffect(() => {
        const fetchSession = async () => {

            try {
                const {
                    accessToken,
                    idToken
                } = (await fetchAuthSession()).tokens ?? {};

                setIdToken(idToken);
                setAccessToken(accessToken);
                if (typeof idToken !== 'undefined'
                    && idToken.payload['cognito:groups'] !== undefined
                    && Array.isArray(idToken.payload['cognito:groups'])
                    && idToken.payload['cognito:groups'].length > 0) {
                    setCognitoGroups(idToken.payload['cognito:groups'] as string[]);
                }

                // if (accessToken && idToken) {
                //     const cognitoAuthTokens = await refreshAuthTokens();
                //     console.debug('cognitoAuthTokens', cognitoAuthTokens)
                // }

            } catch (error) {
                console.trace(
                    'AUTH CONTEXT. FETCH SESSION ERROR',
                    error
                );
            }

        };

        fetchSession();
    }, []);


    useEffect(() => {

        setRole('User')

        if (typeof idToken !== 'undefined'
            && idToken.payload['cognito:groups'] !== undefined
            && Array.isArray(idToken.payload['cognito:groups'])
            && idToken.payload['cognito:groups'].length > 0) {

            const managedUserItem = idToken.payload['cognito:groups'].find((item: any) => item === 'managed_user')
            if (managedUserItem) {
                setRole('ManagedUser')
            }

            const orgAdminItem = idToken.payload['cognito:groups'].find((item: any) => item === 'org_admin')
            if (orgAdminItem) {
                setRole('OrgAdmin')
            }

            const adminItem = idToken.payload['cognito:groups'].find((item: any) => item === 'admin')
            if (adminItem) {
                setRole('Admin')
            }

        }

    }, [idToken])


    return (
        <AuthContext.Provider value={{
            user,
            idToken,
            accessToken,
            cognitoGroups,
            role,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};
