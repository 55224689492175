import {
    Center,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";
import {FlexiButton} from "@flexinet/ui-components";
import {AiOutlineGoogle,} from "react-icons/ai";

import {FaOpenid} from "react-icons/fa";
import {
    signInWithRedirect,
    SignInWithRedirectInput
} from "aws-amplify/auth";


export const SocialSignInFieldset = (props: { username: string }) => {

    const houseEmail = props.username.indexOf('flexi.network') > 0

    const googleSignInWithRedirectInput = {provider: 'Google'} as SignInWithRedirectInput
    const signInWithRedirectInput = {
        provider: {
            custom: 'OpenIdConnectProvider'
        }
    } as SignInWithRedirectInput


    return (
        <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Sign In with Social Account</Text></Title>}
                  px={'xl'}
                  mb={'xl'}>
            <Center>
                <Group
                    my={'lg'}
                >
                    <FlexiButton variant={'outline'} size={'xl'}
                                 leftSection={<AiOutlineGoogle/>}
                                 onClick={(e) => {
                                     e.preventDefault()
                                     signInWithRedirect(googleSignInWithRedirectInput)
                                 }}>Google</FlexiButton>


                    {houseEmail ?
                        <FlexiButton variant={'outline'} size={'xl'}
                                     leftSection={<FaOpenid/>}
                                     onClick={(e) => {
                                         e.preventDefault()
                                         signInWithRedirect(signInWithRedirectInput)
                                     }}
                        >OpenID</FlexiButton> : null}


                </Group>
            </Center>
        </Fieldset>

    )
}

