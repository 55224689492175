import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/UserApplicationsService.tsx';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {UserApplication} from '../types';

const entityKey = 'userApplications';

interface UserApplicationsState {
    byId: { [key: string]: UserApplication },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as UserApplicationsState;


const normalizeRecord = (record: UserApplication) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchUserApplications = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    ({username}: { username: string }) => {

        return fetchRecords(username)
            .then((response) => {
                if (response.data && response.data.items) {
                    return response.data.items.map((record: UserApplication) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchUserApplication = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         username,
         id
     }: { username: string, id: string }) => {
        return fetch(username, id)
            .then((response) => {
                if (response.data) {
                    return normalizeRecord(response.data);
                }
            });
    });

export const saveUserApplication = createAsyncThunk(
    `${entityKey}/add`,
    ({
         username,
         payload
     }: { username: string, payload: UserApplication }) => {

        return add(username, payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateUserApplication = createAsyncThunk(
    `${entityKey}/update`,
    ({
         username,
         id,
         record,
     }: { username: string, id: string, record: UserApplication }) => {

        return update(username, id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteUserApplication = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         username,
         id
     }: { username: string, id: string }) => {

        return deleteRecord(username, id)
            .then(() => id);
    },
);


export const storeUserApplication = createAsyncThunk(
    `${entityKey}/store`,
    (payload: UserApplication) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchUserApplications, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchUserApplication);

                                            // SAVE ONE
                                            saveOneCase(builder, saveUserApplication);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateUserApplication);

                                            updateOneCase(builder, storeUserApplication);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteUserApplication);
                                        },
                                    });

export default componentsSlice.reducer;
