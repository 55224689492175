import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import {PublicHomePage} from './features/home/pages/PublicHomePage';
import {SignOutPage} from './features/home/pages/SignOutPage';

import {IndexPage as DashboardPage} from "./features/dashboard/pages/IndexPage";
import {AccountLayout} from "./features/account/layout";
import {AppLayout} from "./layout/AppLayout";
import {UserApplicationsLayout} from "./features/user-applications/layout.tsx";


import {RequireAuth} from "./utils/RequireAuth.tsx";
import {SubscriptionsLayout} from "./features/subscriptions/layout.tsx";


import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {theme} from "./theme.ts";

import {AuthProvider} from "./context/AuthContext.tsx";
import {SignInLayout} from "./features/sign-in/layout.tsx";
import {SignUpLayout} from "./features/sign-up/layout.tsx";
import {ResetPasswordLayout} from "./features/reset-password/layout.tsx";
import {AuthorizePage} from "./features/oauth2/pages/AuthorizePage.tsx";


const router = createBrowserRouter([{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-in/*',
        element: <SignInLayout/>
    }, {
        path: 'oauth2/authorize*',
        element: <AuthorizePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'sign-up/*',
        element: <SignUpLayout/>
    }, {
        path: 'reset-password/*',
        element: <ResetPasswordLayout/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'user-applications/*',
        element: <RequireAuth> <UserApplicationsLayout/> </RequireAuth>
    }, {
        path: 'account/*',
        element: <RequireAuth> <AccountLayout/> </RequireAuth>
    }, {
        path: 'subscriptions/*',
        element: <RequireAuth> <SubscriptionsLayout/> </RequireAuth>
    }]
}])

export const App = () => {

    return (
        <MantineProvider theme={theme}>
            <Notifications position={'bottom-left'}/>
            <AuthProvider>
                <RouterProvider router={router}/>
            </AuthProvider>
        </MantineProvider>
    );
}

