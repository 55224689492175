import {useEffect} from "react";
import {SignInForm} from "../forms/SignInForm.tsx";
import {useWindowScroll} from "@mantine/hooks";
import {LinkToSignUpPage} from "../../sign-up/components/LinkToSignUpPage.tsx";
import {LinkToResetPasswordPage} from "../../reset-password/components/LinkToResetPasswordPage.tsx";
import {SignInPageGrid} from "../components/SignInPageGrid.tsx";

export const SelectPage = () => {

    const [, scrollTo] = useWindowScroll();

    useEffect(() => {
        scrollTo({y: 0})
    }, []);


    return (
        <>
            <SignInPageGrid>
                <SignInForm/>
                <LinkToResetPasswordPage/>
                <LinkToSignUpPage/>
            </SignInPageGrid>
        </>
    );

}
