import {
    configureStore,
    Store
} from '@reduxjs/toolkit';


import {SubscriptionsReducer} from "../features/subscriptions/reducers";
import {UserApplicationsReducer} from "../features/user-applications/reducers";
import {UserAttributesReducer} from "../features/user-attributes/reducers";

const reducers = {
    userApplications: UserApplicationsReducer,
    userAttributes: UserAttributesReducer,
    subscriptions: SubscriptionsReducer,
};

// function sortObj(obj: { [key: string]: string }) {
//     return Object.keys(obj)
//                  .sort()
//                  .reduce(function (result: { [key: string]: any }, key) {
//                      result[key] = obj[key];
//                      return result;
//                  }, {});
// }
//
// const sorted = sortObj(reducers);


// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                           });


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch