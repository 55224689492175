export const createAccount = `
    mutation createAccount($input: CreateAccountInput!) {
        createAccount(input:$input) {
            id
            name
            email
    }
}`;

export const updateAccount = `
    mutation updateAccount($input: UpdateAccountInput!) {
        updateAccount(input:$input){
            id
            name
        }
}`;

export const suspendAccount = `
    mutation suspendAccount {
        suspendAccount
}`;
