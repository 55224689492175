import {UserApplication} from "../types.ts";
import config from "../../../config";

import {v4 as uuidv4} from 'uuid';

export const applications = [
    {
        id: uuidv4(),
        name: 'Flexi Domains',
        url: config.platform.domains,
        logoUrl: 'https://s3.amazonaws.com/cdn.flexi.network/flexi.domains/images/logo/flexidomains_logo.png',
        rbac: 'GetUserApplication'
    },
    {
        id: uuidv4(),
        name: 'Flexi Email',
        url: config.platform.email,
        rbac: 'GetUserApplication'
    },
    {
        id: uuidv4(),
        name: 'Flexi Bot',
        url: config.platform.bot,
        rbac: 'GetUserApplication'
    },
    {
        id: uuidv4(),
        name: 'Admin Console',
        url: config.platform.console,
        rbac: 'GetAdminApplication'
    },
    {
        id: uuidv4(),
        name: 'Stripe Console',
        url: config.platform.stripe,
        rbac: 'GetAdminApplication'
    },
    {
        id: uuidv4(),
        name: 'Marketplace Console',
        url: config.platform.marketplace,
        rbac: 'GetAdminApplication'
    },
    {
        id: uuidv4(),
        name: 'Billing Console',
        url: config.platform.billing,
        rbac: 'GetAdminApplication'
    },
] as UserApplication[];