import {SimplePageLayout} from '@flexinet/ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';
import {TabbedGrid} from "../tables/TabbedGrid.tsx";
import {IndexPageNavigation} from "../components/IndexPageNavigation";

export const IndexPage = () => {


    return (
        <SimplePageLayout
            pageHeader={<IndexPageHeader/>}
            pageNavigation={<IndexPageNavigation/>}
        >
            <TabbedGrid/>
        </SimplePageLayout>
    );
};
