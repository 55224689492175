import {config as API} from './api';
import {config as AuthConfig} from './auth';
import AppSync from './appsync'
import {ResourcesConfig} from "aws-amplify";

const endpoint = AppSync.endpoint;

// see this - // see this - https://docs.amplify.aws/javascript/tools/libraries/configure-categories/
export const config = {
    Auth: AuthConfig,
    API: {
        REST: API,
        GraphQL: {
            endpoint: endpoint,
            region: 'us-west-2',
            defaultAuthMode: 'userPool'
        }
    },
} as ResourcesConfig;