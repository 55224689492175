import {UserApplication} from "../types.ts";
import {applications} from "../data/applications.tsx";

export const fetchRecords = (username: string): Promise<{ data: { items: UserApplication[] } }> => {
    console.log({username})

    return new Promise(resolve => resolve({
                                              data: {items: applications}
                                          }))
}

export const fetch = (username: string, id: string): Promise<{ data: UserApplication }> => {
    console.debug({username})
    return new Promise(resolve => resolve({data: {id: id}}))
}
export const add = (username: string, payload: UserApplication): Promise<UserApplication> => {
    console.debug({username})
    return new Promise(resolve => resolve(payload))
}

export const update = (username: string, id: string, record: UserApplication): Promise<UserApplication> => {
    console.debug({username})
    console.debug(id)
    return new Promise(resolve => resolve(record))
}

export const deleteRecord = (username: string, id: string) => {
    console.debug({username})
    return new Promise(resolve => resolve(id))
}
