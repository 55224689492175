import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/UserAttributesService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {moduleConfig} from "../config";
import {
    UserAttribute,
    UserAttributesState
} from "../types.ts";

const entityKey = moduleConfig.entityKey;


const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as UserAttributesState;


const normalizeRecord = (record: UserAttribute) => {
    return Object.assign({}, record, {
        id: record.name,
    });
};

// generates pending, fulfilled and rejected
export const fetchUserAttributes = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {

        return fetchRecords()
            .then((response) => {
                if (response) {
                    return response.map((record: UserAttribute) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchUserAttribute = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response as UserAttribute);
                }
            });
    });

export const saveUserAttribute = createAsyncThunk(
    `${entityKey}/add`,
    ({
         payload
     }: { payload: UserAttribute }) => {

        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateUserAttribute = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: UserAttribute }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(record);
                }
            });
    },
);

export const deleteUserAttribute = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchUserAttributes, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchUserAttribute);

                                            // SAVE ONE
                                            saveOneCase(builder, saveUserAttribute);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateUserAttribute);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteUserAttribute);
                                        },
                                    });

export default componentsSlice.reducer;
