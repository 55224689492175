import {
    Route,
    Routes
} from 'react-router-dom';
import {CookiePreferencesPage} from "./pages/CookiePreferencesPage.tsx";
import {DeleteAccountPage} from "./pages/DeleteAccountPage.tsx";

import {IndexPage as ProfilePage} from "../user-attributes/pages/IndexPage.tsx";
import {EditPage as UpdateProfilePage} from "../user-attributes/pages/EditPage.tsx";

export const AccountLayout = () => (
    <Routes>

        <Route path="/" element={<ProfilePage/>}/>

        <Route
            path="/update"
            element={<UpdateProfilePage/>}
        />

        
        <Route
            path="/cookie-preferences"
            element={<CookiePreferencesPage/>}
        />
        <Route
            path="/delete-account"
            element={<DeleteAccountPage/>}
        />


    </Routes>
);
