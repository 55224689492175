import {useCallback} from "react";
import {
    signUp,
    SignUpInput
} from 'aws-amplify/auth';
import {useNavigate} from "react-router-dom";
import {useRandomUserGenerator} from "../../../hooks/useRandomUserGenerator.ts";
import {stage} from "../../../config/stage.ts";
import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo
} from "react-icons/ai";

interface SignInFormFields {
    email: string;
    given_name: string;
    family_name: string;
    password: string;
    confirmPassword: string;
}

export const useSignUpForm = () => {

    const navigate = useNavigate()

    const submitForm = async (values: SignInFormFields) => {

        const input = {
            username: values.email,
            password: values.password,
            options: {
                userAttributes: {
                    email: values.email,
                    given_name: values.given_name,
                    family_name: values.family_name,
                },
                // optional
                autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
            }
        } as SignUpInput


        try {
            const {
                userId,
                nextStep
            } = await signUp(input)

            console.debug('USER ID', userId)

            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({
                                          'event': 'sign_up',
                                      });
            }


            if (nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
                console.debug('CONFIRM_SIGN_UP')

                showNotification({
                                     title: 'Notification',
                                     icon: <AiOutlineInfo/>,
                                     message: `Confirm sign up`,
                                     color: 'teal',
                                 })

                navigate('/sign-up/confirm', {state: {username: values.email}})
            }


        } catch (e: any) {

            console.error(e)

            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: e.message,
                                 color: 'red',
                             })

        }
    }


    const initialValues: SignInFormFields = {
        email: '',
        given_name: '',
        family_name: '',
        password: '',
        confirmPassword: ''
    };

    const {
        generateRandomUser,
    } = useRandomUserGenerator()

    const {
        email,
        firstName,
        lastName,
        password,
        confirmPassword,
    } = generateRandomUser()

    if (stage === 'ide') {
        initialValues.email = email
        initialValues.given_name = firstName
        initialValues.family_name = lastName
        initialValues.password = password
        initialValues.confirmPassword = confirmPassword
    }


    const handleSubmit = useCallback(
        (values: SignInFormFields) => {
            return submitForm(values)
                .then(() => {
                    console.log('Form submitted!');
                });
        },
        [],
    );


    const validate = (values: SignInFormFields) => {
        const errors = {} as { email: string };
        if (!values.email) {
            errors.email = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    return {
        initialValues,
        handleSubmit,
        validate
    };
};
