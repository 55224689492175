import {ResetPasswordForm} from "../forms/ResetPasswordForm.tsx";
import {useEffect} from "react";
import {useWindowScroll} from "@mantine/hooks";
import {Container} from "@mantine/core";
import {IndexPageLayout} from "@flexinet/ui-components";

export const ChangePage = () => {
    const [, scrollTo] = useWindowScroll();

    useEffect(() => {
        scrollTo({y: 0})
    }, []);


    return (
        <IndexPageLayout title={'Reset Password'} tools={[]}>
            <Container px={'15%'}>
                <ResetPasswordForm/>
            </Container>
        </IndexPageLayout>
    );

}
