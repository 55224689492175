import {RightHandPanel} from "../components/RightHandPanel.tsx";
import {useMarketingWorkflow} from "../hooks/useMarketingWorkflow.ts";
import {useSearchParams} from "react-router-dom";


export const IndexPage = () => {

    const [searchParams,] = useSearchParams()

    const application = searchParams.get('application')
    const plan = searchParams.get('plan')

    const {
        context,
    } = useMarketingWorkflow();

    const modifiedContext = context;
    if (application) {
        modifiedContext.application = application;
    }
    if (plan) {
        modifiedContext.plan = plan;
    }

    return (
        <RightHandPanel context={context}/>
    );

}
