export type MarketingWorkflowContext = {
    greeting: string
    enabled: boolean
    application?: string
    plan?: string
}

export const useMarketingWorkflow = () => {

    const context = {
        greeting: 'Welcome',
        enabled: false,
        application: '',
        plan: ''
    }

    return {
        context,
    };
};
