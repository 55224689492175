import {
    Center,
    Group,
    Text
} from "@mantine/core";

import {Link} from "react-router-dom";

export const LinkToSignUpPage = () => {
    return (
        <Center>
            <Group>
                <Text>Don't have an account?</Text>
                <Link to={'/sign-up'}>Sign Up</Link>
            </Group>
        </Center>
    );

}
