import {
    ButtonProps,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";

import {
    Field,
    Form,
    Formik
} from 'formik';
import {
    FlexiButton,
    FlexiCancelButton,
    FormikTextInput
} from "@flexinet/ui-components";
import {useForgotPasswordForm} from "../hooks/useForgotPasswordForm.tsx";
import {ForgotPasswordRecordSchema} from "./RecordSchema.tsx";
import {useNavigate} from "react-router-dom";


const SubmitButton = (props: ButtonProps & { type: string }) => <FlexiButton {...props} />

export const ResetPasswordForm = () => {

    const navigate = useNavigate()

    const {
        initialValues,
        handleSubmit,
    } = useForgotPasswordForm()

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={ForgotPasswordRecordSchema}
                // validate={validate}
                onSubmit={handleSubmit}
            >
                {({
                      isSubmitting,
                  }) => (
                    <>

                        <Form>
                            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Reset Password</Text></Title>}
                                      px={'xl'}
                                      mb={'xl'}>
                                <Field
                                    name={'username'}
                                    label={'Email'}
                                    type="email"
                                    description={<Text size={'0.8em'} fw={500}>i.e. john.doe@mycompany.com</Text>}
                                    my={'lg'}
                                    component={FormikTextInput}
                                />
                                <Group justify={'flex-end'}>
                                    <FlexiCancelButton variant={'subtle'} onClick={() => navigate('/sign-in')}/>
                                    <SubmitButton disabled={isSubmitting} type={'submit'}>Submit</SubmitButton>
                                </Group>
                            </Fieldset>

                        </Form>

                    </>

                )}
            </Formik>
        </div>
    )
};

