import {
    Box,
    Container,
    Group
} from "@mantine/core";
import {IndexPage as MarketingPage} from "../../marketing/pages/IndexPage.tsx";
import {ReactNode} from "react";

import classes from './SignInPageGrid.module.css';

export const SignInPageGrid = ({children}: { children: ReactNode }) => {
    return (
        <Group className={classes.root}>
            <Box className={classes.forms}>
                <Container px={'15%'}>
                    {children}
                </Container>
            </Box>

            <Box className={classes.marketing}>
                <MarketingPage/>
            </Box>

        </Group>
    );

}
