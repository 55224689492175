import PropTypes from 'prop-types';

import UserApplicationGridProfile from './UserApplicationGridProfile.tsx';

import {
    Box,
    SimpleGrid
} from '@mantine/core';


import {UserApplication} from "../types.ts";
import {useAuth} from "../../../hooks/useAuth.ts";


const UserApplicationsGrid = ({data}: { data: UserApplication[] }) => {

    const {
        idToken,
        accessToken
    } = useAuth();

    const getCustomTokenFragment = async (
        application: UserApplication,
        expiresIn = '3600',
        tokenType = 'Bearer'
    ) => {

        if (!accessToken) {
            throw new Error('access token is undefined');
        }

        if (!idToken) {
            throw new Error('id token is undefined');
        }


        if (typeof idToken === 'undefined') {
            throw new Error('current session is undefined');
        }

        if (typeof application['integration-options'] !== 'undefined') {
            const integrationOptions = application['integration-options'];

            if (integrationOptions['receive-tokens']) {

                const params = {
                    access_token: accessToken.toString(),
                    id_token: idToken.toString(),
                    token_type: tokenType,
                    expires_in: expiresIn,
                };

                const searchParams = new URLSearchParams(params);
                return '#' + searchParams.toString();
            } else {
                return '';
            }
        }

        return '';
    };


    return (
        <SimpleGrid
            cols={{
                base: 4,
                xl: 4,
                lg: 4,
                md: 3,
                sm: 2,
                xs: 1
            }}
        >
            {data.map((application: UserApplication, index: number) => {
                const urlFragment = getCustomTokenFragment(
                    application,
                );

                const cloudApplication = Object.assign({}, application, {
                    'link-fragment': urlFragment,
                });

                return (
                    <Box key={index}>
                        <UserApplicationGridProfile cloudApplication={cloudApplication}/>
                    </Box>
                );
            })}
        </SimpleGrid>
    );
};

export default UserApplicationsGrid;

UserApplicationsGrid.propTypes = {
    data: PropTypes.array,
};
