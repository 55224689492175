import {useEffect} from 'react';

import {Center} from '@mantine/core';
import {PlatformLoader} from '@flexinet/ui-components';
import {signOut} from 'aws-amplify/auth';

export const SignOutPage = () => {
    useEffect(() => {
        const doSignOut = async () => {
            signOut()
                .then(() => {

                    if (typeof window.dataLayer !== 'undefined') {
                        window.dataLayer.push({
                                                  'event': 'sign_out',
                                              });
                    }

                    window.location.href = '/';
                });
        };

        doSignOut();
    }, []);

    return (
        <Center>
            <PlatformLoader/>
        </Center>
    );
};
