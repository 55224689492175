import {
    ReactElement,
    useEffect,
} from 'react';

import {
    Box,
    Center,
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useUserApplications} from '../hooks/useUserApplications';

import {
    SimpleTableBatchAction,
    SimpleTablePagination,
    TabbedComponent,
    TabComponentTab,
} from '@flexinet/ui-components';

import PropTypes from 'prop-types';
import UserApplicationsGrid from "../components/UserApplicationsGrid.tsx";
import {I18n} from "aws-amplify/utils";
import {UserApplication} from "../types.ts";
import {useAuth} from "../../../hooks/useAuth.ts";


const CollectionGrid = ({
                            limit,
                            handleItemsPerPageChange,
                            settings,
                            keyword,
                            handleFilterByKeyword,
                            extraTools,
                            visibleItems,
                            totalPages,
                            visibleCount,
                            handlePageChange
                        }: {
    limit: string,
    handleItemsPerPageChange: (pageSize: string | null) => void,
    settings?: { label: string, onClick: () => void }[],
    keyword: string,
    handleFilterByKeyword: (keyword: string) => void,
    extraTools?: ReactElement[],
    visibleItems: UserApplication[],
    totalPages: number,
    visibleCount: number,
    handlePageChange: (page: number) => void
}) => {
    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                keyword={keyword}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <UserApplicationsGrid data={visibleItems}/>
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    )
}

export const TabbedGrid = () => {

    const {
        user
    } = useAuth();

    if (typeof user === 'undefined' || !user) {
        throw new Error('user is required parameter')
    }


    const {
        visibleItems,
        limit,
        keyword,
        totalPages,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    } = useUserApplications(user.username);


    useEffect(() => {
        fetchRecords();
    }, []);


    const tabs: TabComponentTab[] = []

    tabs.push({
                  title: 'All Apps',
                  badgeContent: visibleItems.length.toString(),
                  component:
                      visibleItems.length > 0 ? (
                          <Box px={20}>
                              <CollectionGrid
                                  visibleItems={visibleItems}
                                  limit={limit}
                                  keyword={keyword}
                                  totalPages={totalPages}
                                  visibleCount={visibleCount}
                                  handleFilterByKeyword={handleFilterByKeyword}
                                  handleItemsPerPageChange={handleItemsPerPageChange}
                                  handlePageChange={handlePageChange}
                              />
                          </Box>
                      ) : (
                          <Center>
                              <strong>{I18n.get('No Applications Found')}</strong>
                          </Center>
                      ),
              })

    return <TabbedComponent tabs={tabs}/>

};

TabbedGrid.propTypes = {
    extraTools: PropTypes.array,
    settings: PropTypes.object,
};