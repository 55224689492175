import {
    Group,
    Paper,
    Stack,
    Title
} from "@mantine/core";
import {AccountProfile} from "../components/AccountProfile.tsx";
import {AiOutlineUser} from "react-icons/ai";
import {SocialProfile} from "../components/SocialProfile.tsx";
import {PhotoProfile} from "../components/PhotoProfile.tsx";
import {LocationProfile} from "../components/LocationProfile.tsx";
import {ContactProfile} from "../components/ContactProfile.tsx";


export const IndexPage = () => {

    return (
        <Stack>
            <Group><AiOutlineUser size={30} color={"black"}/><Title order={2}>Account Profile</Title></Group>
            <Paper p={"xl"}>
                <AccountProfile/>
                <ContactProfile/>
                <LocationProfile/>
                <PhotoProfile/>
                <SocialProfile/>
            </Paper>
        </Stack>
    );
};
