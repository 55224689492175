import {stage} from '../stage';
import {ResourcesConfig} from "aws-amplify";

const cognito = {
    prod: {
        domain: 'flexinet-main-user-pool-prod.auth.us-west-2.amazoncognito.com',
        redirectSignIn: ['https://portal.flexi.network'],
        redirectSignOut: ['https://portal.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_bxdNeqDGn',
        userPoolClientId: '467rlh361q573kme7313svd3sb',
        identityPoolId: 'us-west-2:a566963b-048f-4ffa-9df6-89814738257a',
    },
    test: {
        domain: 'flexinet-main-user-pool-test.auth.us-west-2.amazoncognito.com',
        redirectSignIn: ['https://portal.test.flexi.network'],
        redirectSignOut: ['https://portal.test.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_UuiFFepVO',
        userPoolClientId: '4l6lu4764mgkk0s6jfq5ebq2c8',
        identityPoolId: 'us-west-2:d7a2833c-863d-46e4-85a3-16b116ef25fc',
    },
    dev: {
        domain: 'flexinet-main-user-pool-dev.auth.us-west-2.amazoncognito.com',
        redirectSignIn: ['portal.dev.flexi.network'],
        redirectSignOut: ['https://portal.dev.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_INBgfMat1',
        userPoolClientId: '52m9svts4nvks7qi0ft8lt0oct',
        identityPoolId: 'us-west-2:d806f8ff-118f-4444-917f-e85833880089',
    },
    ide: {
        domain: 'flexinet-main-user-pool-dev.auth.us-west-2.amazoncognito.com',
        redirectSignIn: ['https://localhost:8011'],
        redirectSignOut: ['https://localhost:8011'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_INBgfMat1',
        userPoolClientId: '52m9svts4nvks7qi0ft8lt0oct',
        identityPoolId: 'us-west-2:d806f8ff-118f-4444-917f-e85833880089',
    },
};

const oauth = {
    // Domain name
    domain: cognito[stage].domain,

    // Authorized scopes
    scopes: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
    ],

    // Callback URL
    redirectSignIn: cognito[stage].redirectSignIn,

    // Sign out URL
    redirectSignOut: cognito[stage].redirectSignOut,

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: 'code',

    // optional, for Cognito hosted ui specified options
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true,
    },
};

export const config = {
    Cognito: {
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: cognito[stage].userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: cognito[stage].userPoolClientId,

        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: cognito[stage].identityPoolId,

        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'code', // 'code' | 'link'


        // REQUIRED - Amazon Cognito Region
        region: cognito[stage].region,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,


        loginWith: {
            oauth: oauth,
        }

    }
} as ResourcesConfig['Auth'];