import {useCallback} from "react";
import {
    autoSignIn,
    confirmSignUp,
    ConfirmSignUpInput
} from "aws-amplify/auth";
import {useReturnTo} from "../../../hooks/useReturnTo.ts";
import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo
} from "react-icons/ai";
import {useLocation} from "react-router-dom";


interface ConfirmSignUpFormFields {
    username: string;
    confirmationCode: string;
}


export const useConfirmSignUpForm = () => {

    const {
        getReturnTo,
        generateRedirectUrl
    } = useReturnTo()

    const {state} = useLocation();

    const initialValues: ConfirmSignUpFormFields = {
        username: state && state.username ? state.username : '',
        confirmationCode: '',
    };


    const submitForm = async (values: ConfirmSignUpFormFields) => {

        const input = {
            username: values.username,
            confirmationCode: values.confirmationCode,
        } as ConfirmSignUpInput

        try {

            const {
                isSignUpComplete,
                nextStep
            } = await confirmSignUp(input);


            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({
                                          'event': 'confirm_sign_up',
                                      });
            }


            console.debug('isSignUpComplete', isSignUpComplete)
            console.debug('nextStep', nextStep)


            if (nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
                console.debug('COMPLETE_AUTO_SIGN_IN')

                try {
                    const signInOutput = await autoSignIn();
                    console.log(signInOutput);
                    // handle sign-in steps

                    showNotification({
                                         title: 'Notification',
                                         icon: <AiOutlineInfo/>,
                                         message: `Signing you in...Please wait!`,
                                         color: 'teal',
                                     })

                    if (await getReturnTo()) {
                        const url = await generateRedirectUrl()
                        window.location.assign(url)
                    } else {
                        window.location.assign('/dashboard')
                    }

                } catch (e: any) {
                    console.error(e.message)
                    showNotification({
                                         title: 'Error',
                                         icon: <AiOutlineExclamation/>,
                                         message: e.message,
                                         color: 'red',
                                     })
                }


            }


            if (nextStep.signUpStep === 'DONE') {

                showNotification({
                                     title: 'Notification',
                                     icon: <AiOutlineInfo/>,
                                     message: `Done. Redirecting...`,
                                     color: 'teal',
                                 })

                if (await getReturnTo()) {
                    const url = await generateRedirectUrl()
                    window.location.assign(url)
                } else {
                    window.location.assign('/dashboard')
                }

            }


        } catch (e: any) {

            console.error(e);

            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: e.message,
                                 color: 'red',
                             })

        }

    }

    const handleSubmit = useCallback(
        (values: ConfirmSignUpFormFields) => {
            return submitForm(values)
                .then(() => {
                    console.log('Form submitted!');
                });
        },
        [],
    );

    return {
        initialValues,
        handleSubmit,
    };
};
