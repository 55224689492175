import * as Yup from 'yup';


export const SignUpFormRecordSchema = Yup.object()
                                         .shape({
                                                    'given_name': Yup.string()
                                                                     .required('given name is required field'),
                                                    'family_name': Yup.string()
                                                                      .required('family name is required field'),
                                                    'email': Yup.string()
                                                                .email()
                                                                .required('email address is required field'),
                                                    'password': Yup.string()
                                                                   .min(8, 'Minimum 8 chars')
                                                                   .required('password is required field'),
                                                    'confirmPassword': Yup.string()
                                                                          .min(8, 'Minimum 8 chars')
                                                                          .required('confirm password is required field'),
                                                    'acknowledgment': Yup.bool()
                                                                         .required('acknowledgment is required field')
                                                });

export const ConfirmSignUpFormRecordSchema = Yup.object()
                                                .shape({
                                                           'username': Yup.string()
                                                                          .email()
                                                                          .required('email address is required field'),
                                                           'confirmationCode': Yup.string()
                                                                                  .required('password is required field'),
                                                       });

