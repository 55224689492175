import {
    FlexiEditButton,
    PlatformPropertyCard
} from "@flexinet/ui-components";
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {
    Group,
    Stack,
    Title
} from "@mantine/core";
import {useNavigate} from "react-router-dom";

export const LocationProfile = () => {

    const navigate = useNavigate()


    const {
        locale,
        zoneInfo,
    } = useUserAttributes();


    const properties = [
        {
            label: 'Locale',
            value: locale,
            span: 6,
        }, {
            label: 'Zone Info',
            value: zoneInfo,
            span: 6,
        }];


    return (
        <Stack my={'xl'}>
            <Group justify={'space-between'}>
                <Title order={2}>Location</Title>
                <FlexiEditButton
                    onClick={() => {
                        navigate('/account/update/#location')
                    }}
                />
            </Group>
            <PlatformPropertyCard properties={properties}></PlatformPropertyCard>
        </Stack>
    );
};
