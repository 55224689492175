import {showSuccessNotification} from "../../../components/ServiceNotifications.tsx";
import {
    fetchUserAttributes,
    updateUserAttribute,
    type UpdateUserAttributeOutput
} from "aws-amplify/auth";
import {UserAttribute} from "../types.ts";

const attributesMap = {
    givenName: 'given_name',
    familyName: 'family_name',
    middleName: 'middle_name',
    name: 'name',
    email: 'email',
    birthdate: 'birthdate',
    zoneInfo: 'zoneinfo',
    locale: 'locale',
    website: 'website',
    picture: 'picture',
    address: 'address',
    phoneNumber: 'phone_number',
    gender: 'gender',
    profile: 'profile',
    nickname: 'nickname',
    accountId: 'custom:account_id',
    accountAdmin: 'custom:account_admin',
    // sub: 'sub',
    // identities: 'identities',
    // emailVerified: 'email_verified'
} as { [key: string]: string }

const reverseAttributesMap = {
    sub: 'sub',
    identities: 'identities',
    given_name: 'givenName',
    family_name: 'familyName',
    middle_name: 'middleName',
    name: 'name',
    email: 'email',
    email_verified: 'emailVerified',
    birthdate: 'birthdate',
    zoneinfo: 'zoneInfo',
    locale: 'locale',
    website: 'website',
    picture: 'picture',
    address: 'address',
    phone_number: 'phoneNumber',
    gender: 'gender',
    profile: 'profile',
    nickname: 'nickname',
    'custom:account_admin': 'accountAdmin',
    'custom:account_id': 'accountId'
} as { [key: string]: string }


export const fetchRecords = async () => {

    const output = await fetchUserAttributes()

    const records = [] as UserAttribute[]
    Object.keys(output)
          .forEach((key: string) => {
              const normalizedKey = reverseAttributesMap[key]
              records.push({
                               name: normalizedKey,
                               value: output[key] as string
                           } as UserAttribute)
          })

    return records
};

export const fetch = async (id: string) => {
    const all = await fetchRecords()
    return all.find(item => item.name === id)
}

export const add = async (values: UserAttribute) => {
    showSuccessNotification('User attribute added')
    return values;
}

export const update = async (id: string, record: UserAttribute) => {
    console.debug('Updating user attribute', id, record)

    const attributeKey = attributesMap[record.name as string]
    if (attributeKey) {
        handleUpdateUserAttribute(attributeKey, record.value as string)
    }

    return record;
};

const handleUpdateUserAttribute = async (attributeKey: string, value: string) => {
    try {
        const output = await updateUserAttribute({
                                                     userAttribute: {
                                                         attributeKey,
                                                         value
                                                     }
                                                 });
        handleUpdateUserAttributeNextSteps(output);
    } catch (error) {
        console.log(error);
    }
}

const handleUpdateUserAttributeNextSteps = (output: UpdateUserAttributeOutput) => {
    const {nextStep} = output;

    let codeDeliveryDetails;

    switch (nextStep.updateAttributeStep) {
        case 'CONFIRM_ATTRIBUTE_WITH_CODE':
            codeDeliveryDetails = nextStep.codeDeliveryDetails;
            showSuccessNotification(`Message sent using ${codeDeliveryDetails?.deliveryMedium} to ${codeDeliveryDetails?.destination}`)
            console.log(
                `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
            );
            // Collect the confirmation code from the user and pass to confirmUserAttribute.
            break;
        case 'DONE':
            console.log(`attribute was successfully updated.`);
            break;
    }
}

export const deleteRecord = async (id: string) => {
    showSuccessNotification(`profile attribute deleted ${id}`)
}

