import {
    IRBACPermissionStatement,
    IRBACRole,
    IRBACRoleConfig
} from "../../types";

import admin from './rbac/admin';
import user from './rbac/user';
import orgAdmin from "./rbac/org-admin.ts";
import managedUser from "./rbac/managed-user.ts";

export const rbacCacheKey = '-pac-app-rbac';

export const roleToCogntioGroupMap: { [key: string]: string } = {
    Admin: '^admin$',
    OrgAdmin: '^org_admin$',
    ManagedUser: '^managed_user$',
    User: '^us-west-2_',
};

export type RbacDocumentType = {
    roles: IRBACRole[],
    routes: { [key: string]: any },
    policies: { name: string }[],
    statements: IRBACPermissionStatement[],
    permissions: IRBACRoleConfig[]
}


const roleConfigs: IRBACRoleConfig[] = []

export const defaultRbacDocument: RbacDocumentType = {
    roles: [
        {name: 'Admin'},
        {name: 'OrgAdmin'},
        {name: 'ManagedUser'},
        {name: 'User'},
    ],
    policies: [],
    statements: [],
    routes: [
        {
            path: '/applications',
            httpMethod: 'GET',
            actionName: 'ListApplications',
        },
        {
            path: '/applications/add',
            httpMethod: 'POST',
            actionName: 'CreateApplication',
        },
        {
            path: '/applications/{id}',
            httpMethod: 'GET',
            actionName: 'GetApplication',
        },
        {
            path: '/applications/{id}',
            httpMethod: 'PUT',
            actionName: 'UpdateApplication',
        },
        {
            path: '/applications/{id}',
            httpMethod: 'DELETE',
            actionName: 'DeleteApplication',
        },
        {
            path: '/profile',
            httpMethod: 'GET',
            actionName: 'UpdateUserProfile',
        },
        {
            path: '/settings',
            httpMethod: 'GET',
            actionName: 'ListUserSettings',
        },
    ],
    permissions: roleConfigs
        .concat(admin)
        .concat(user)
        .concat(managedUser)
        .concat(orgAdmin)
};
