import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {suspendRecord} from "../services/AccountsService.ts";


interface DeleteAccountFormFields {
    confirmation: boolean;
}


export const useDeleteAccountForm = () => {

    const navigate = useNavigate()

    const initialValues: DeleteAccountFormFields = {
        confirmation: true,
    };

    const submitForm = async (values: DeleteAccountFormFields) => {

        console.debug('delte account form values', values)

        // await deleteUser();
        await suspendRecord();
        navigate('/sign-out')
    }


    const handleSubmit = useCallback(
        (values: DeleteAccountFormFields) => {
            return submitForm(values)
                .then(() => {
                    console.log('Form submitted!');
                });
        },
        [],
    );

    const handleCancel = () => {
        navigate('/account')
    }

    return {
        initialValues,
        handleSubmit,
        handleCancel
    };
};
