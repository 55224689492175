export const basicUserPermissions = [
    {
        Effect: 'Allow',
        Action: [
            'GetUserApplication',
        ],
        Resource: '*',
    },
    {
        Effect: 'Allow',
        Action: [
            'GetUserAccount',
            'UpdateAccount',
        ],
        Resource: '*',
    },
    {
        Effect: 'Allow',
        Action: [
            'GetDashboard',
            'ListUserSettings',
            'ListUserAgreements',
            'SignOut',
        ],
        Resource: '*',
    },
];

export default {
    roleName: 'ManagedUser',
    permissionStatements: basicUserPermissions,
};
