import {
    Container,
    Grid,
} from '@mantine/core';
import {Dashlet} from "../components/Dashlet";

import {useDashboard} from "../hooks/useDashboard.tsx";
import {DashletType} from "../types.ts";

export const IndexPage = () => {

    const {applications} = useDashboard()

    return (
        <Container>
            <Grid p={'md'}>
                {applications.map((item: DashletType, index: number) => {
                    return (
                        <Grid.Col key={index} span={{
                            xl: 4,
                            lg: 4,
                            md: 6,
                            sm: 12,
                            xs: 12,
                        }}>
                            <Dashlet item={item}/>
                        </Grid.Col>
                    );
                })}
            </Grid>
        </Container>
    );
};
