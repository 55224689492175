export const listSubscriptions = `
   query listSubscriptions($filter: SubscriptionsFilterInput) {
       listSubscriptions(filter: $filter) {
           items {
              id
              customerId,
              productId
           }
       }
   }
`;


export const getSubscription = `
   query getSubscription($id:ID!) {
       getSubscription(id:$id) {
            id
            customerId
            productId
       } 
}`;
