import {useCallback} from "react";
import {
    confirmSignIn,
    ConfirmSignInInput
} from "aws-amplify/auth";
import {showNotification} from "@mantine/notifications";
import {AiOutlineExclamation} from "react-icons/ai";


interface ConfirmSignInFormFields {
    challengeResponse: string
}

export const useConfirmSignInForm = () => {

    const initialValues: ConfirmSignInFormFields = {
        challengeResponse: '',
    };

    const submitForm = async (values: ConfirmSignInFormFields) => {

        const input = {
            challengeResponse: values.challengeResponse,
        } as ConfirmSignInInput;

        try {
            const output = await confirmSignIn(input)
            console.debug(output)

            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({
                                          'event': 'confirm_sign_in',
                                      });
            }


        } catch (e: any) {

            console.error(e)

            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: e.message,
                                 color: 'red',
                             })

        }


    }

    const handleSubmit = useCallback(
        (values: ConfirmSignInFormFields) => {
            return submitForm(values)
                .then(() => {
                    console.log('Form submitted!');
                });
        },
        [],
    );

    return {
        initialValues,
        handleSubmit,
    };
};
