import {Cache} from 'aws-amplify/utils';
import {useSearchParams} from "react-router-dom";
import {
    useEffect,
    useState
} from "react";

export const useCustomState = () => {
    const [customState, setCustomState] = useState<string | undefined>();

    const [params] = useSearchParams();

    const saveCustomState = () => {
        if (params.get('state')) {
            Cache.setItem('customState', params.get('state'))
        }
    };

    const getCustomState = () => {
        return Cache.getItem('customState')
                    .then(result => result);
    };
    const clearCustomState = () => {
        Cache.removeItem('customState')
    }

    useEffect(() => {
        const getCustomState = async () => {
            const state = await Cache.getItem('customState')
            setCustomState(state)
        }
        getCustomState()
    }, [])

    return {
        customState,
        saveCustomState,
        getCustomState,
        clearCustomState
    };
};
