import {useReturnTo} from "../../../hooks/useReturnTo.ts";
import {SignUpForm} from "../forms/SignUpForm.tsx";
import {LinkToSignInPage} from "../../sign-in/components/LinkToSignInPage.tsx";
import {SignUpPageGrid} from "../components/SignUpPageGrid.tsx";
import {LinkToConfirmSignUpPage} from "../components/LinkToCofnirmSignUpPage.tsx";
import {useAuth} from "../../../hooks/useAuth.ts";
import {
    useNavigate,
    useSearchParams
} from "react-router-dom";
import {useEffect} from "react";
import {AppSelectionComponent} from "../components/AppSelectionComponent.tsx";
import {IndexPage as MarketingPage} from "../../marketing/pages/IndexPage";


export const IndexPage = () => {
    const [searchParams,] = useSearchParams()

    const application = searchParams.get('application')
    const plan = searchParams.get('plan')


    const navigate = useNavigate()

    const {
        saveReturnTo,
    } = useReturnTo()

    saveReturnTo()

    const {user} = useAuth()
    useEffect(
        () => {
            if (user) {
                navigate('/dashboard')
            }
        }, []
    )

    if (!application || !plan) {
        return (
            <SignUpPageGrid leftPanel={<AppSelectionComponent/>} rightPanel={<MarketingPage/>}/>
        );

    }

    if (application && plan) {

        const leftPanel = <><SignUpForm/>
            <LinkToConfirmSignUpPage/>
            <LinkToSignInPage/>
        </>

        return (
            <SignUpPageGrid leftPanel={leftPanel} rightPanel={<MarketingPage/>}/>
        );

    }


}
