import {ReactNode} from 'react';
import PropTypes from 'prop-types';
import {useAuth} from "../hooks/useAuth.ts";
import {PlatformLoader} from "@flexinet/ui-components";


export const RequireAuth = ({children}: { children: ReactNode }) => {

    const {user} = useAuth();
    if (!user) {
        return <PlatformLoader message={'Signing in...Please, wait!'}/>
    }

    return children;
};

RequireAuth.propTypes = {
    children: PropTypes.node,
};
