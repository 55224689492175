import PropTypes from 'prop-types';

import {
    Container,
    Fieldset,
    Text,
    Title
} from '@mantine/core';

import {
    FormikAutocomplete,
    FormikDatePickerInput,
    FormikRadioGroup,
    FormikTextInput
} from '@flexinet/ui-components';

import locales from '../../../data/locales.json';
import {Field} from "formik";

declare namespace Intl {
    type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

    function supportedValuesOf(input: Key): string[];
}

const aryIanaTimeZones = Intl.supportedValuesOf('timeZone');


export const FormComponent = () => {

    return (
        <Container>
            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Profile</Text></Title>} px={'xl'} mb={'xl'}>
                <Field
                    name='name'
                    label='Name'
                    description='Please enter given name'
                    withAsterisk
                    my={'xl'}
                    component={FormikTextInput}
                />
                <Field
                    name='email'
                    label='Email'
                    description='Please enter email'
                    my={'xl'}
                    component={FormikTextInput}
                />

                <Field
                    name='givenName'
                    label='Given Name'
                    description='Please enter given name'
                    withAsterisk
                    my={'xl'}
                    component={FormikTextInput}
                />
                <Field
                    name='familyName'
                    label='Family Name'
                    description='Please enter family name'
                    withAsterisk
                    my={'xl'}
                    component={FormikTextInput}
                />
                <Field
                    name='middleName'
                    label='Middle Name'
                    description='Please enter middleName'
                    my={'xl'}
                    component={FormikTextInput}
                />
            </Fieldset>


            <Fieldset id={'contact'} legend={<Title order={3}><Text tt={'uppercase'}>Contact</Text></Title>} px={'xl'}
                      mb={'xl'}>
                <Field
                    name='address'
                    label='Address'
                    description='Please enter address'
                    my={'xl'}
                    component={FormikTextInput}
                />
                <Field
                    name='website'
                    label='Website'
                    description='Please enter website'
                    my={'xl'}
                    component={FormikTextInput}
                />
                <Field
                    name='phoneNumber'
                    label='Phone Number'
                    description='Please enter phone number +17141234567'
                    my={'xl'}
                    component={FormikTextInput}
                />
            </Fieldset>


            <Fieldset id={'location'} legend={<Title order={3}><Text tt={'uppercase'}>Location</Text></Title>} px={'xl'}
                      mb={'xl'}>

                <Field
                    name='locale'
                    label='Locale'
                    description='Please enter locale'
                    data={locales.map(locale => {
                        return {
                            id: locale.key,
                            value: locale.key,
                            label: locale.name
                        }
                    })}
                    my={'xl'}
                    component={FormikAutocomplete}
                />

                <Field
                    name='zoneinfo'
                    label='Zoneinfo'
                    description='Please enter zoneinfo'
                    data={aryIanaTimeZones.map(zone => {
                        return {
                            id: zone,
                            value: zone,
                            label: zone
                        }
                    })}
                    my={'xl'}
                    component={FormikAutocomplete}
                />

            </Fieldset>

            <Fieldset id={'photo'} legend={<Title order={3}><Text tt={'uppercase'}>Photo</Text></Title>} px={'xl'}
                      mb={'xl'}>

                <Field
                    name='picture'
                    label='Picture'
                    description='Please enter picture'
                    my={'xl'}
                    component={FormikTextInput}
                />

            </Fieldset>

            <Fieldset id={'social'} legend={<Title order={3}><Text tt={'uppercase'}>Social</Text></Title>} px={'xl'}
                      mb={'xl'}>


                <Field
                    name='profile'
                    label='Profile'
                    description='Please enter profile url'
                    my={'xl'}
                    component={FormikTextInput}
                />

                <Field
                    name='nickname'
                    label='Nickname'
                    description='Please enter nickname'
                    my={'xl'}
                    component={FormikTextInput}
                />

                <Field
                    name='birthDate'
                    label='Birth Date'
                    description='Please enter birth date'
                    my={'xl'}
                    component={FormikDatePickerInput}
                />

                <Field
                    name='gender'
                    label='Gender'
                    description='Please select gender'
                    data={[{
                        label: 'Female',
                        value: 'female'
                    }, {
                        label: 'Male',
                        value: 'male'
                    }, {
                        label: 'Rather not say',
                        value: ''
                    }]}
                    my={'xl'}
                    component={FormikRadioGroup}
                />

            </Fieldset>


        </Container>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
