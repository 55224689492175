import {
    Center,
    Group
} from "@mantine/core";

import {Link} from "react-router-dom";

export const LinkToResetPasswordPage = () => {
    return (
        <Center>
            <Group my={'lg'}>
                <Link to={'/reset-password'}>Forgot Password?</Link>
            </Group>
        </Center>
    );

}
