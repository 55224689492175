import {getAccount} from './queries';
import {
    suspendAccount,
    updateAccount,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';


import {
    Account,
    GetAccount,
    SuspendAccount,
    UpdateAccount
} from "../types.ts";

import {
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";

import {generateClient} from 'aws-amplify/api';

const client = generateClient();

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetAccount>>({
                                                                            query: getAccount,
                                                                            variables: {id: id},
                                                                        });
        return response.data?.getAccount;
    } catch (e) {
        showErrorNotifications(e)
    }
};


export const update = async (id: string, record: Account) => {

    console.debug('IN UPDATE', id, record);


    try {
        const response = await client.graphql<GraphQLQuery<UpdateAccount>>({
                                                                               query: (updateAccount),
                                                                               variables: {input: record},
                                                                           });

        showSuccessNotification('Account updated successfully');
        return response.data?.updateAccount;
    } catch (e) {
        showErrorNotifications(e)
    }
};

export const suspendRecord = async () => {
    try {
        const response = await client.graphql<GraphQLQuery<SuspendAccount>>({
                                                                                query: (suspendAccount),
                                                                                variables: {},
                                                                            });
        showSuccessNotification();
        return response.data?.suspendAccount;
    } catch (e) {
        showErrorNotifications(e)
    }
};
