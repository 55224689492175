import {useCallback} from "react";
import {useReturnTo} from "../../../hooks/useReturnTo.ts";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import {signIn} from "aws-amplify/auth";
import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo,
    AiOutlineWarning
} from "react-icons/ai";


interface SignInFormFields {
    username: string;
    password: string;
}


export const useSignInForm = () => {

    const navigate = useNavigate()

    const {
        getReturnTo,
        generateRedirectUrl
    } = useReturnTo()


    const {state} = useLocation();

    const initialValues: SignInFormFields = {
        username: state && state.username ? state.username : '',
        password: '',
    };

    const submitForm = async (values: SignInFormFields) => {

        try {
            const {
                nextStep
            } = await signIn({
                                 username: values.username,
                                 password: values.password
                             })

            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({
                                          'event': 'sign_in',
                                      });
            }


            if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                console.debug('CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED')

                showNotification({
                                     title: 'New Password is required',
                                     icon: <AiOutlineWarning/>,
                                     message: `Please, change your password!`,
                                     color: 'orange',
                                 })

                navigate('/sign-in/confirm')
            }

            if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
                console.debug('CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE')

                showNotification({
                                     title: 'Confirmation is required',
                                     icon: <AiOutlineWarning/>,
                                     message: `Please, confirm!`,
                                     color: 'orange',
                                 })

                navigate('/sign-in/confirm')
            }

            if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
                console.debug('CONFIRM_SIGN_IN_WITH_TOTP_CODE')

                showNotification({
                                     title: 'Confirm with one-time password',
                                     icon: <AiOutlineWarning/>,
                                     message: `Please, confirm with one-time password!`,
                                     color: 'orange',
                                 })

                navigate('/sign-in/confirm')
            }

            if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {
                console.debug('CONFIRM_SIGN_IN_WITH_SMS_CODE')

                showNotification({
                                     title: 'Confirmation is required',
                                     icon: <AiOutlineWarning/>,
                                     message: `Please, confirm with SMS code!`,
                                     color: 'orange',
                                 })

                navigate('/sign-in/confirm')
            }

            if (nextStep.signInStep === 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION') {
                console.debug('CONTINUE_SIGN_IN_WITH_MFA_SELECTION')

                showNotification({
                                     title: 'Continue with MFA selection',
                                     icon: <AiOutlineWarning/>,
                                     message: `Please, continue with multi-factor authentication (MFA) selection!`,
                                     color: 'orange',
                                 })

                navigate('/sign-in/confirm')
            }

            if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                console.debug('CONFIRM_SIGN_UP')

                showNotification({
                                     title: 'Confirmation is required',
                                     icon: <AiOutlineWarning/>,
                                     message: `Please, confirm!`,
                                     color: 'orange',
                                 })

                navigate('/confirm-sign-up')
            }

            if (nextStep.signInStep === 'RESET_PASSWORD') {
                console.debug('RESET_PASSWORD')

                showNotification({
                                     title: 'Password reset is required',
                                     icon: <AiOutlineWarning/>,
                                     message: `Please, reset your password!`,
                                     color: 'orange',
                                 })

                navigate('/reset-password')
            }

            if (nextStep.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
                console.debug('CONTINUE_SIGN_IN_WITH_TOTP_SETUP')

                showNotification({
                                     title: 'TOTP Setup is required',
                                     icon: <AiOutlineWarning/>,
                                     message: `Please, continue with TOTP setup!`,
                                     color: 'orange',
                                 })

                navigate('/setup-totp')
            }


            if (nextStep.signInStep === 'DONE') {

                showNotification({
                                     title: 'Notification',
                                     icon: <AiOutlineInfo/>,
                                     message: `Authenticated. Redirecting...Please, wait!`,
                                     color: 'teal',
                                 })

                try {
                    if (await getReturnTo()) {
                        const url = await generateRedirectUrl()
                        window.location.assign(url)
                    } else {
                        window.location.assign('/dashboard')
                    }

                } catch (e: any) {
                    console.debug(e.message)
                    window.location.assign('/dashboard')
                }


            }


        } catch (e: any) {

            if (e.name === 'UserNotConfirmedException') {
                console.error(e.message)
            } else if (e.name === 'NotAuthorizedException') {
                console.error(e.message)
            } else if (e.name === 'UserNotFoundException') {
                console.error(e.message)
            }

            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: e.message,
                                 color: 'red',
                             })


        }


    }


    const handleSubmit = useCallback(
        (values: SignInFormFields) => {
            return submitForm(values)
                .then(() => {
                    console.debug('Form submitted!');
                });
        },
        [],
    );


    const validate = (values: SignInFormFields) => {
        const errors = {} as { username: string };
        if (!values.username) {
            errors.username = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
        ) {
            errors.username = 'Invalid email address';
        }
        return errors;
    }

    return {
        initialValues,
        handleSubmit,
        validate
    };
};
