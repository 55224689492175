import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {Cache} from 'aws-amplify/utils';


interface EmailAddressFormFields {
    username: string;
    rememberMe: boolean;
}


export const useEmailAddressForm = () => {

    const navigate = useNavigate()

    const initialValues: EmailAddressFormFields = {
        username: '',
        rememberMe: false,
    };

    const submitForm = async (values: EmailAddressFormFields) => {
        if (values.rememberMe) {
            await Cache.setItem('sign-in-preferences', values);
        } else {
            await Cache.removeItem('sign-in-preferences')
        }

        navigate('/sign-in/select', {state: {username: values.username}})
    }


    const handleSubmit = useCallback(
        (values: EmailAddressFormFields) => {
            return submitForm(values)
                .then(() => {
                    console.log('Form submitted!');
                });
        },
        [],
    );

    return {
        initialValues,
        handleSubmit,
    };
};
