import {FaReact} from "react-icons/fa";
import {useEffect} from "react";
import {useUserApplications} from "../../user-applications";
import {UserApplication} from "../../user-applications/types.ts";
import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useCustomState} from "../../../hooks/useCustomState.ts";

export const useDashboard = () => {

    const {
        user,
    } = useAuth();

    const {evaluateAction} = useAccessControl()

    const {
        items,
        fetchRecords
    } = useUserApplications(user ? user.username : '')

    useEffect(() => {
        if (user !== null && typeof user !== 'undefined') {
            fetchRecords()
        }
    }, [user]);


    const {
        idToken,
        accessToken,
    } = useAuth();

    const {
        customState,
        clearCustomState
    } = useCustomState()

    const filtered = items.filter((item: { rbac: string }) => evaluateAction(item.rbac))

    const applications = filtered.map((item: UserApplication) => {
        // console.debug('item', item)
        return {
            app: item.name,
            locationOriginal: item.url +
                `#id_token=${idToken}&access_token=${accessToken}&token_type=Bearer&expires_in=3600&state=${customState}`,
            location: item.url,
            logo: item.logoUrl ? item.logoUrl : '',
            icon: <FaReact/>,
            onClick: () => {
                clearCustomState()
            }
        };
    })


    return {applications: applications}

}