import {
    FlexiEditButton,
    PlatformPropertyCard
} from "@flexinet/ui-components";
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {
    Group,
    Stack,
    Title
} from "@mantine/core";
import {useNavigate} from "react-router-dom";

export const ContactProfile = () => {

    const navigate = useNavigate();

    const {
        address,
        website,
        phoneNumber,
    } = useUserAttributes();

    const properties = [
        {
            label: 'Address',
            value: address,
            span: 6,
        }, {
            label: 'Web Site',
            value: website,
            span: 6,
        }, {
            label: 'Phone Number',
            value: phoneNumber,
            span: 6,
        }];


    return (
        <Stack my={'xl'}>
            <Group justify={'space-between'}>
                <Title order={2}>Contact</Title>
                <FlexiEditButton
                    onClick={() => {
                        navigate('/account/update/#contact')
                    }}
                />
            </Group>
            <PlatformPropertyCard properties={properties}></PlatformPropertyCard>
        </Stack>
    );

};
