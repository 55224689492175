import {AiOutlineUser} from "react-icons/ai";
import {
    ActionIcon,
    CloseButtonProps,
    Group,
    Paper,
    Text
} from "@mantine/core";
import {FlexiCloseButtonIcon} from "@flexinet/ui-components";

const CloseButtonIcon = (props: CloseButtonProps & { onClick: () => void }) => <FlexiCloseButtonIcon {...props} />

export const UserAvatar = () => {
    return (
        <ActionIcon radius={'xl'} variant={'outline'} size={'3em'}>
            <AiOutlineUser size={'2.3em'}/>
        </ActionIcon>
    )
}

export const UserEmail = ({email}: { email: string }) => {
    return (
        <Text size={'lg'} fw={500}>{email}</Text>
    )
}

export const UserAccountProfile = ({
                                       username,
                                       onClear
                                   }: { username: string, onClear: () => void }) => {
    return (
        <Paper shadow="xs" p="md" my={'xl'}>
            <Group justify={'space-between'}>
                <Group>
                    <UserAvatar></UserAvatar>
                    <UserEmail email={username}></UserEmail>
                </Group>
                <CloseButtonIcon size={'lg'} onClick={onClear}/>
            </Group>
        </Paper>
    )
}
