import {
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {SelectPage} from "./pages/SelectPage.tsx";
import {ConfirmPage} from "./pages/ConfirmPage.tsx";

export const SignInLayout = () => {

    return (
        <Routes>
            <Route
                path='/'
                element={<IndexPage/>}
            />
            <Route
                path='select'
                element={<SelectPage/>}
            />
            <Route
                path='confirm'
                element={<ConfirmPage/>}
            />
        </Routes>
    );
};
