import {
    deleteUserApplication,
    fetchUserApplication,
    saveUserApplication,
    updateUserApplication,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {UserApplication} from '../types';

export const useUserApplication = (username: string, userApplicationId?: string) => {

    if (typeof username === 'undefined') {
        throw new Error('username is required parameter')
    }

    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, userApplicationId ? userApplicationId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof userApplicationId === 'undefined') {
            throw new Error('userApplicationId is required parameter');
        }
        dispatch(fetchUserApplication({
                                          username: username,
                                          id: userApplicationId
                                      }));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: UserApplication) => {
        dispatch(saveUserApplication({
                                         username: username,
                                         payload: values
                                     }));
    };

    const updateRecord = (values: UserApplication) => {
        if (typeof userApplicationId === 'undefined') {
            throw new Error('userApplicationId is required parameter');
        }

        dispatch(updateUserApplication({
                                           username: username,
                                           id: userApplicationId,
                                           record: values,
                                       }));
    };

    const deleteRecord = () => {
        if (typeof userApplicationId === 'undefined') {
            throw new Error('userApplicationId is required parameter');
        }
        dispatch(deleteUserApplication({
                                           username: username,
                                           id: userApplicationId
                                       }));
        
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
