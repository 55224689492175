import {
    Amplify,
    ResourcesConfig
} from 'aws-amplify';

import {stage} from '../config/stage';

// get config
import {config} from '../config/amplify';
import {ConsoleLogger} from 'aws-amplify/utils';

Amplify.configure(config as ResourcesConfig);

stage === 'ide'
    ? (ConsoleLogger.LOG_LEVEL = 'DEBUG')
    : (ConsoleLogger.LOG_LEVEL = 'INFO');

declare let window: any;

window.LOG_LEVEL = 'DEBUG';
