import {useNavigate} from "react-router-dom";
import {
    Box,
    Center,
    Paper,
    Stack
} from "@mantine/core";
import {UserApplication} from "../../user-applications/types.ts";
import {FlexiButton} from "@flexinet/ui-components";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {applications} from "../../user-applications/data/applications.tsx";


export const AppSelectionComponent = () => {

    const navigate = useNavigate()

    const userApps = applications.filter(app => app.rbac === 'GetUserApplication')

    return (
        <>
            <Center>
                <Stack>
                    <Box>
                        <h1>Please, select application and plan:</h1>
                    </Box>

                    {userApps.map((app: UserApplication, index: number) => {

                        return (
                            <Paper style={{height: '6em'}} radius={'lg'}>
                                <FlexiButton variant={'outline'} radius={'lg'} key={index} style={{
                                    width: '100%',
                                    height: '100%'
                                }} onClick={() => window.open(app.url + '/pricing')}>
                                    {app.name}
                                </FlexiButton>
                            </Paper>
                        );
                    })}


                </Stack>
            </Center>

            <Center mt={'xl'}>
                <FlexiButton onClick={() => navigate('/')}
                             leftSection={<AiOutlineArrowLeft/>}
                >
                    Go back
                </FlexiButton>
            </Center>
        </>
    )

}

