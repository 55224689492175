import {ConfirmResetPasswordForm} from "../forms/CofirmForm.tsx";
import {LinkToSignUpPage} from "../../sign-up/components/LinkToSignUpPage.tsx";
import {ResetPasswordPageGrid} from "../components/ResetPasswordPageGrid.tsx";

export const ConfirmPage = () => {

    return (
        <ResetPasswordPageGrid>
            <ConfirmResetPasswordForm/>
            <LinkToSignUpPage/>
        </ResetPasswordPageGrid>
    );

}
