import {
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {ConfirmPage} from "./pages/ConfirmPage.tsx";
import {ChangePage} from "./pages/ChangePage.tsx";

export const ResetPasswordLayout = () => {

    return (
        <Routes>
            <Route
                path='/'
                element={<IndexPage/>}
            />
            <Route
                path='confirm'
                element={<ConfirmPage/>}
            />
            <Route
                path='change'
                element={<ChangePage/>}
            />
        </Routes>
    );
};
