import {evaluatePolicyStatement} from './evaluator';

import {
    defaultRbacDocument,
    roleToCogntioGroupMap
} from './rbac-config';
import {IRBACPermissionStatement} from "../../types";

export const isAllowed = (userRoles: string[], action: string, resource = '*') => {


    if (userRoles === null) {
        throw new Error('User Roles is null');
    }

    if (userRoles.length === 0) {
        return false
    }


    const statements = getPermissionStatements(userRoles);

    let isAllowed = false;
    const evaluationResults: boolean[] = [];

    statements.forEach((statement) => {
        const result = evaluatePolicyStatement(statement, action, resource);
        evaluationResults.push(result);
    });

    isAllowed = evaluationResults.includes(true);

    return isAllowed;
};

export const isRouteAllowed = (userRoles: string[], match: { path: string }, resource = '*') => {
    const routes = getRoutes();

    let selected = null;

    if (Object.prototype.hasOwnProperty.call(routes, match.path)) {
        selected = routes[match.path];
    }

    let action = null;

    if (selected !== null) {
        action = selected['GET'];
    }

    return isAllowed(userRoles, action, resource);
};

export const getDocument = () => {
    // const record = Cache.getItem(rbacCacheKey, {
    //     callback: () => {
    //         console.debug('CONTEXT USER DEFAULT', defaultRbacDocument);
    //         return {
    //             // document: {
    //             //     roles: [],
    //             //     policies: [],
    //             //     statements: [],
    //             //     routes: [],
    //             //     permissions: [],
    //             // },

    //             document: defaultRbacDocument,
    //         };
    //     },
    // });

    // console.debug('CONTEXT USER RECORD', record);

    // return record.document;

    return defaultRbacDocument;
};

export const getRoles = () => {
    const {roles} = getDocument();
    return roles;
};

export const getPolicies = () => {
    const {policies} = getDocument();
    return policies;
};

export const getStatements = () => {
    const {statements} = getDocument();
    return statements;
};

export const getPermissions = () => {
    const {permissions} = getDocument();
    return permissions;
};

export const getRoutes = () => {
    const {routes} = getDocument();
    return routes;
};

export const getPolicyStatementsForUserRole = (userRole: string) => {
    let statements: IRBACPermissionStatement[] = [];

    const permissions = getPermissions();
    if (Array.isArray(permissions)) {
        const permission = permissions.find(
            (item) => item.roleName === userRole,
        );
        if (permission) {
            statements = permission.permissionStatements;
        }
    }

    return statements;
};

export const getRoleNames = (userGroups: string[]) => {
    const out: string[] = [];

    const {roles} = getDocument();

    if (!Array.isArray(roles)) {
        return out;
    }

    roles.forEach((role) => {
        const pattern = roleToCogntioGroupMap[role.name];
        const re = new RegExp(pattern);
        userGroups.forEach((group) => {
            if (re.test(group) === true) {
                out.push(role.name);
            }
        });
    });

    // remove duplicates
    return [...new Set(out)];
};

export const getPermissionStatements = (userRoles: string[]) => {
    const statements: IRBACPermissionStatement[] = [];

    userRoles.forEach((userRole) => {
        const roleStatements = getPolicyStatementsForUserRole(userRole);

        if (!Array.isArray(roleStatements)) {
            return;
        }

        roleStatements.forEach((roleStatement) => {
            statements.push(roleStatement);
        });
    });

    return statements;
};
