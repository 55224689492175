import {
    Box,
    Container,
    Grid,
    Paper,
    Text
} from "@mantine/core";
import {IndexPageLayout} from "@flexinet/ui-components";
import {DeleteAccountForm} from "../forms/DeleteAccountForm.tsx";
import {AiOutlineWarning} from "react-icons/ai";


export const DeleteAccountPage = () => {

    return (
        <IndexPageLayout title={'Delete Account'} tools={[]}>

            <Container>

                <Paper p={'md'} my={'xl'}>
                    <Grid>
                        <Grid.Col span={1}>
                            <AiOutlineWarning size={'3em'} color={'orange'}/>
                        </Grid.Col>
                        <Grid.Col span={11}>
                            <Box>
                                <Text>I understand that I may delete my account at any time. I understand that I may
                                    lose
                                    access to my account data if I delete it.</Text>
                            </Box>
                        </Grid.Col>
                    </Grid>
                </Paper>


                <DeleteAccountForm/>

            </Container>

        </IndexPageLayout>
    );
};
