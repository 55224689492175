import {
    ButtonProps,
    Fieldset,
    Group,
    Text,
    Title
} from "@mantine/core";

import {
    Field,
    Form,
    Formik
} from 'formik';
import {
    FlexiButton,
    FormikCheckbox
} from "@flexinet/ui-components";
import {DeleteAccountFormSchema} from "./RecordSchema.ts";
import {useDeleteAccountForm} from "../hooks/useDeleteAccountForm.ts";


const RegisterButton = (props: ButtonProps & { type: string }) => <FlexiButton {...props} />

export const DeleteAccountForm = () => {

    const {
        initialValues,
        handleSubmit,
        handleCancel
    } = useDeleteAccountForm()


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={DeleteAccountFormSchema}
                onSubmit={handleSubmit}
            >
                {({
                      isSubmitting,
                  }) => (

                    <Form>
                        <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Confirm Delete Account</Text></Title>}
                                  px={'xl'}
                                  mb={'xl'}>


                            <Field name="confirmation"
                                   label={"I understand that I may lose access to my account if I delete it."}
                                   component={FormikCheckbox}
                                   withAsterisk
                                   my={'lg'}
                            ></Field>


                            <Group justify={'flex-end'}>
                                <FlexiButton variant={'subtle'} onClick={handleCancel}>Cancel</FlexiButton>
                                <RegisterButton variant={'filled'} disabled={isSubmitting}
                                                type={'submit'}>Confirm</RegisterButton>
                            </Group>
                        </Fieldset>

                    </Form>

                )}
            </Formik>
        </div>
    )
};

