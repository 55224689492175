import PropTypes from 'prop-types';

import {
    Avatar,
    Image
} from '@mantine/core';

import {AiOutlineAppstore} from 'react-icons/ai';
import {UserApplication} from '../types';

export const UserApplicationLogo = ({
                                        record,
                                        size,
                                    }: {
    record: UserApplication,
    size: string
}) => {
    const alt = record.name;

    if (record.logoUrl) {
        return <Image src={record.logoUrl}/>
    }

    return (
        <Avatar alt={alt} size={size}>
            <AiOutlineAppstore/>
        </Avatar>
    );
};

UserApplicationLogo.propTypes = {
    record: PropTypes.shape({
                                name: PropTypes.string,
                            }),
    size: PropTypes.number,
};

UserApplicationLogo.defaultProps = {
    size: 70,
};

